import {  createContext, useState,useRef , useEffect } from "react";
 
 
export const  themecontext = createContext();


const Themeprovider = ({children})=>{

 const  [ data , setdata]  = useState( )
 const savedTheme = localStorage.getItem('theme');
 
 const [theme, setTheme] = useState(savedTheme ? JSON.parse(savedTheme) : true);
 useEffect(() => {
     localStorage.setItem('theme', JSON.stringify(theme));
   }, [theme]);
 
  
 
 const handelswitchopen = ()=>{
    setdata(null)
 }

 const handelswitchtheme = ()=>{
    setTheme(!theme)
 }


 

 

    return( 
        <themecontext.Provider  value={{theme,setTheme,handelswitchtheme  ,   handelswitchopen   }}>
{children}

        </themecontext.Provider>
    )
}
export default Themeprovider;