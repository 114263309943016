import { createContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { clearDataAndLogout } from "../Functions/Logout";
import { CheckNotification } from "../Notification/CheckNotification";
import Dexie from "dexie";
// import { initializeFirebaseMessaging, onFirebaseMessage } from "../Firebase";
import { addNewItemID } from "../Functions/addNewItemID";
import { sendFcmT } from "../API/SendFcmT";


export const Usercontext = createContext();

const Userprovider = ({ children }) => {
  const [Massage, setMassage] = useState(null);
  const [User, setUser] = useState(null);
  const [Receiptes, setReceiptes] = useState(null);
  const [card, setCard] = useState([]);
  const [FirstFetch, setFirstFetch] = useState(true);
  const [update, setUpdate] = useState(true);
  const [IsCardChange, setIsCardChange] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [Token, setToken] = useState("");
 

  // Creating the LocalDB
  const Database = new Dexie("Database");
  Database.version(1).stores({
    receipts: "created_at",
    users: "id",
    cards: "id",
  });

  // Creating DB if it's not
  useEffect(() => {
    var request = indexedDB.open("notificationDB", 1);
    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      const objectStore = db.createObjectStore("notifications", {
        keyPath: "id",
      });
    };
    CheckNotification(setData);
  }, []);

  // get all the notifications and display them
  useEffect(() => {
    if (data.length > 0) {
      setMassage(data);
      data?.forEach((receipt) => {
        const jsonReceipt = JSON.parse(receipt.data);
        const RececiptData = {
          json: JSON.parse(jsonReceipt.json.replace(/'/g, '"')),
          id: parseInt(jsonReceipt.id),
          shop: parseInt(jsonReceipt.shop),
          created_at: jsonReceipt.created_at,
          receipt: jsonReceipt.receipt,
          updated_at: jsonReceipt.updated_at,
          file_type: jsonReceipt.file_type,
        };
        const checkAndAddReceiptsToDB = async () => {
          try {
            const existingReceipt = await Database.receipts.get(
              RececiptData?.id
            );

            if (!existingReceipt) {
              await Database.transaction("rw", Database.receipts, async () => {
                await Database.receipts.add(RececiptData);
              });

              setReceiptes((prevReceiptes) => {
                // Check if prevReceiptes is null (initial state)
                if (prevReceiptes === null) {
                  // If it's null, return a new array with only the new receiptData
                  return [{ ...RececiptData, isNew: true }];
                } else {
                  // If it's not null, return a new array with the new receiptData at the beginning
                  return [{ ...RececiptData, isNew: true }, ...prevReceiptes];
                }
              });
              addNewItemID(RececiptData?.id, setHighlightedItems);
            } else {
              await Database?.receipts.update(RececiptData);
            }
          } catch {}
        };

        checkAndAddReceiptsToDB();
      });
    }
  }, [data]); // The empty dependency array e

  // saving the Receiptes in the LocalDB
  useEffect(() => {
    const checkAndAddReceiptsToDB = async () => {
      for (const receipt of Receiptes) {
        const existingReceipt = await Database.receipts.get(receipt?.id);
        if (!existingReceipt) {
          // Receipt doesn't exist in the local DB, add it
          await Database?.receipts.put(receipt);
        } else {
          // Receipt already exists in the local DB
        }
      }
    };
    if (Receiptes) {
      checkAndAddReceiptsToDB();
    }
  }, [User, Receiptes]);

  // Get anonymous User
  useEffect(() => {
    if (User?.length > 0) {
      axios
        .post("/api/clients/anonymous", {})
        .then((response) => {
          const { data } = response;
          Cookies.set("access_token", "Token " + data.access_token);
          setUser(data.user);
        })
        .catch((error) => {
          console.error();
        });
    }
  }, []);

  // Saving the User Info in LocalDB
  useEffect(() => {
    const addOrUpdateUserData = async () => {
      try {
        // Fetch existing user
        const existingUser = await Database.users.get(User?.id);

        if (!existingUser) {
          // User doesn't exist in the local DB, add it
          await Database.users.put(User);
        } else {
          // User already exists in the local DB, update it
          await Database.users.update(User?.id, User);
        }
      } catch (error) {}
    };
    if (User) {
      addOrUpdateUserData();
    }
  }, [User]);

  // Saving the Cards in the localDB
  useEffect(() => {
    const checkAndAddReceiptsToDB = async () => {
      for (const card1 of card) {
        const existingReceipt = await Database.cards.get(card1?.id);
        if (!existingReceipt) {
          // card doesn't exist in the local DB, add it
          await Database?.cards.put(card1);
        } else {
          // card already exists in the local DB
        }
      }
    };
    if (card) {
      checkAndAddReceiptsToDB();
    }
  }, [User, card]);

  // fetching user & receipts & cards  info from the local DB
  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        var users = await Database.users.toArray();
        const allReceipts = await Database.receipts.toArray();
        const allCards = await Database?.cards?.toArray();
        if (allCards.length > 0) {
          setCard(allCards.reverse());
        }
        if (allReceipts.length > 0) {
          setFirstFetch(false);
          setReceiptes(allReceipts.reverse());
          setIsLoading(false);
        }
        // Fetch the single user (assuming there is only one user)
        if (users.length > 0) {
          // There should be only one user, so we take the first one
          const user = users[0];
          setUser(user);
        } else {
        }
      } catch (error) {}
    };
    if (!User) {
      fetchLocalData();
    }
  }, [User]);

  // Example of using the function to add a new item ID (replace 123 with your actual ID)

  useEffect(() => {
    // const fetchToken = async (setToken) => {
    //   try {
    //     const currentToken = await initializeFirebaseMessaging(setToken);
    //   } catch (error) {}
    // };

    // fetchToken(setToken);
    // messageListener  of the firebase notifications
    const messageListener = (payload) => {
      const receiptData = {
        json: JSON.parse(payload.data.json.replace(/'/g, '"')),
        id: parseInt(payload.data.id),
        shop: parseInt(payload.data.shop),
        created_at: payload.data.created_at,
        receipt: payload.data.receipt,
        updated_at: payload.data.updated_at,
        file_type: payload.data.file_type,
      };
      const checkAndAddReceiptsToDB = async () => {
        try {
          const existingReceipt = await Database.receipts.get(receiptData?.id);

          if (!existingReceipt) {
            await Database.transaction("rw", Database.receipts, async () => {
              await Database.receipts.put(receiptData);
            });
            addNewItemID(receiptData?.id, setHighlightedItems);
            setReceiptes((prevReceiptes) => {
              // Check if prevReceiptes is null (initial state)
              if (prevReceiptes === null) {
                // If it's null, return a new array with only the new receiptData
                return [receiptData];
              } else {
                // If it's not null, return a new array with the new receiptData at the beginning
                return [receiptData, ...prevReceiptes];
              }
            });
          } else {
            await Database?.receipts.update(receiptData);
          }
        } catch {}
      };
      checkAndAddReceiptsToDB();
      setMassage(payload.data);
    };

    // onFirebaseMessage(messageListener);

    // Cleanup function
    return () => {};
  }, [User]);

  // Get all the fidelity_cards from the server
  useEffect(() => {
    if (User && FirstFetch) {
      const token = Cookies.get("access_token");

      axios
        .get("/api/clients/fidelity_cards", {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const { data } = response;
          setCard(data.reverse());
        })
        .catch((error) => {
          console.error();
        });
    }
  }, [IsCardChange, User]);

  // Get all the User Info from the server
  useEffect(() => {
    const token = Cookies.get("access_token");

    if (token && User && FirstFetch) {
      axios
        .get("/api/clients/me", {
          headers: {
            Authorization: token,
          },
        })
        .then(({ data }) => {
          setUser(data); // Set the user state with the received data
        })
        .catch((err) => {});
    }
  }, [update]);

  // Sending the Fcm_Token to the Server
  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      axios
        .put(
          "/api/clients/me",
          {
            fcm_token: Token,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(({ data }) => {})
        .catch((err) => {});
    }
  }, [update, Token]);

  // Get all the receipts from the server
  useEffect(() => {
    if (User && FirstFetch) {
      const token = Cookies.get("access_token");
      axios
        .get("/api/clients/receipts", {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const { data } = response;
          setReceiptes(data.reverse());
          setIsLoading(false);
        })
        .catch((error) => {
          console.error();
          setReceiptes(null);
        });
    }
  }, [User]);

  // Geting the token from the URL and Store it
  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (token) {
      clearDataAndLogout(setCard, setReceiptes, setUser, setFirstFetch);
      Cookies.set("access_token", "Token " + token);
      const access_token = "Token " + token;
      axios
        .get("/api/clients/me", {
          headers: { Authorization: access_token },
        })
        .then(({ data }) => {
          setUser(data);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get("access_token");

    if (!User && !token) {
      axios
        .post("/api/clients/anonymous", {})
        .then((response) => {
          const { data } = response;
          Cookies.set("access_token", "Token " + data.access_token);
          sendFcmT("Token " + data.access_token);
          setUser(data?.user);
        })
        .catch((error) => {
          console.error();
        });
      console.log("User");

      console.log(User);
      console.log("User");
    }
  }, []);


 

  return (
    <Usercontext.Provider
      value={{
        isLoading,
        setIsLoading,
        highlightedItems,
        setHighlightedItems,
        setFirstFetch,
        Massage,
        setMassage,
        IsCardChange,
        setCard,
        setReceiptes,
        setIsCardChange,
        Receiptes,
        card,
        User,
        setUser,
        update,
        setUpdate,
      }}
    >
      {children}
    </Usercontext.Provider>
  );
};
export default Userprovider;
