import React, { useEffect, useState } from "react";
import ScannDirctly from "./ScannDirctly";
import closeBtn from '../../assest/CloseBtn.svg'
import border from '../../assest/border.svg'
import { Link, useNavigate } from "react-router-dom";

const CodeBarScanner = () => {
  const [Code, setCode] = useState();
  const [change, setChange] = useState(true);
  const navto = useNavigate()
  const Close = () =>{
    navto(-1)
  }

  return (
    <div className=" text-center bg-black h-full w-full  fixed top-0 right-0 z-40 pt-[8rem] ">
      <img onClick={Close} className=" w-[1.4rem] h-[1.4rem]  absolute top-5 right-5 " src={closeBtn} alt="" />
      <div className=" w-full flex  justify-center items-center ">
        <span className=" relative w-[80%] ">
          {/* <img className=" absolute z-50 top-0 h-[95%] w-full right-0" src={border} alt="" /> */}
          <ScannDirctly setCode={setCode} />
        </span>
      </div>
      <p className=" text-white   mt-6">Hold your card in front of the <br /> camera to scan the barcode</p>
      {Code && (
        <div className=" items-center justify-between px-7 flex flex-row text-center w-full h-[5rem]  fixed bottom-[20%] right-0 bg-white text-4xl font-semibold ">
          {Code}{" "}
          <button
            onClick={() => setCode()}
            className=" p-2 px-4 rounded-md bg-main text-white"
          >
            Clear
          </button>
        </div>
      )}
      <span className=" text-center absolute bottom-20 right-[50%] text-white transform translate-x-[50%] flex flex-col gap-2">
        <p>OR</p>
        <Link
          to={"/Cards/addcartmanully"}
          className="   z-40 px-3 flex  items-center  w-full h-[3rem]  font-semibold bg-main rounded-md   "
        >
          Add Your Card Manually
        </Link>
      </span>
    </div>
  );
};

export default CodeBarScanner;
