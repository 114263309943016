import { React, useEffect, useState , useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";
const BillingChart = ({  Refund,Paid , UnPaid ,PartPaid, state ,Period }) => {

   const [PDatachart ,setPDatachart ] = useState()
   const [UPDatachart ,setUPDatachart ] = useState()
   const [RDatachart ,setRDatachart ] = useState()
   const [PPDatachart ,setPPDatachart ] = useState()
   const {t , i18n } = useTranslation();
   const {theme} = useContext(themecontext)
   var isArabic = i18n.language.startsWith('ar');
  useEffect(()=>{

    const processRawData = (data , state) => {
      const processedData = [];
     const time = data?.time
      const Data = data?.data
  for (let i = 0; i < Data?.length; i++) {
         const entry = Data[i];
         const Time = time[i];
  
          const entryDate = new Date(Time);
            let timeUnitValue;
            if (Period === 'Last year' || Period === 'This year' ) {
              timeUnitValue = entryDate.toLocaleString('en-us', {  month:'short' });
            } else if (  Period === 'Today' ) {
              timeUnitValue = entryDate.toLocaleString('en-us', {  hour: 'numeric', minute: 'numeric'  });
            } 
            else {
              timeUnitValue = entryDate.toLocaleString('en-us', {  day:'2-digit' });
            }
            // Create the object in the required format
            const dataObject = { x: timeUnitValue, y: entry};
  
            // Push the object to the processedData array
            processedData.push(dataObject);
    
  }
      
  if(state == 'Paid' ){
    setPDatachart(processedData);
  } else if (state == 'UnPaid' ){  
    setUPDatachart(processedData)
  }
  else if (state == 'PartPaid' ){
    setPPDatachart(processedData);
   
  }
       else if (state == 'Refund' ){
    setRDatachart(processedData);
   
  }
  
    };
   
    
    processRawData(Paid , 'Paid');
    processRawData(UnPaid  , 'UnPaid');
    processRawData(Refund  , 'Refund');
    processRawData(PartPaid  , 'PartPaid');

  
  },[Refund,Paid , UnPaid ,PartPaid] )
  


 const index = [0,1,2,3]

  const options = {
    chart: {
      animations: {
        enabled: true, // Enable animations
        dynamicAnimation: {
          speed: 50, // Animation speed in milliseconds
        },
      },
 foreColor: theme ?  "#333" : "#ffefef",
      fontFamily: "Archivo",
      height: 258,
      id: "Iz30P",
      toolbar: {
        show: false,
      },
      type: "area",
      width: 480,
      fontUrl: null,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        isFunnel: false,
        isFunnel3d: true,
        dataLabels: {
          total: {
            enabled: false,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#373d3f",
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
      },
      bubble: {
        zScaling: true,
      },
      treemap: {
        dataLabels: {
          format: "scale",
        },
      },
      radialBar: {
        hollow: {
          background: "#fff",
        },
        dataLabels: {
          name: {},
          value: {},
          total: {},
        },
      },
      pie: {
        donut: {
          labels: {
            name: {},
            value: {},
            total: {},
          },
        },
      },
    },

    dataLabels: {
      enabled: false,
      offsetX: -7,
      offsetY: -4,
      style: {
        fontWeight: 400,
      },
      dropShadow: {
        blur: 2,
      },
    },
    fill: {},
    grid: {
      show: false,
      strokeDashArray: 8,
      xaxis: {
        lines: {
          show: true,
        },
      },
      column: {},
      padding: {
        top: -13,
        right: 0,
        left: -13,
      },
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      floating: true,
      fontSize: 14,
      offsetY: 0,
      markers: {
        width: 20,
        height: 20,
        strokeWidth: 5,
        radius: 49,
      },
      itemMargin: {
        vertical: 0,
      },
    },
    markers: {
      size: 6,
      strokeColors: "#40FF26",
      strokeWidth: 0,
      fillOpacity: 0.7,
      hover: {
        size: 4,
        sizeOffset: 6,
      },
    },
    series:
      state == 0 
        ? [


 
            {
              name: "Area",
              data: PDatachart?.slice(0,14),
              color: "#00D979", // Color for series 1
             
            },
           
          ]
        : state == 1
        ? [
            {
              name: "Area",
              data: UPDatachart?.slice(0,14),
              color: "#F42929", // Color for series 1
              
            },
          ]
        : state == 2
        ? [
            {
              name: "Area",
              data: PPDatachart?.slice(0,14),
              color: "#FF8125", // Color for series 1
             
            },
          ]:[
            {
              name: "Area",
              data: RDatachart?.slice(0,14),
              color: "#29D7FD", // Color for series 1
              
            },
          ],

    stroke: {
      lineCap: "round",
      width: 4,
      fill: {
        type: "solid",
        opacity: 0.85,
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          inverseColors: false,
          opacityFrom: 0.65,
          opacityTo: 0.5,
          stops: [0, 100, 100],
          colorStops: [],
        },
      },
    },
    xaxis: {
      type: "numeric",
      offsetY: -5,
      labels: {
        trim: true,
        style: {
          fontWeight: 200,
        },
      },
      group: {
        groups: [],
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 400,
          cssClass: "",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: "dataPoints",
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      offsetY: 10,
      tickAmount: 6,
      labels: {
        style: {
          colors: [null, null, null, null, null, null, null],
          fontSize: 10,
          fontWeight: 200,
        },
      },
      title: {
        style: {},
      },
    },
  };
  return (
    <div className=" relative w-full h-full  mt-[.7rem] ">
      <div className="  w-full flex  pb-[1.3rem]  rounded-[.32rem] pt-[1.7rem] pl-[0rem] pr-[1.8rem] border-solid border-[1px]    flex-col   h-[300px] ">
     <div className=" flex flex-row items-center w-full gap-1 justify-between">
       <span className=  { `w-full  justify-end flex flex-row gap-1 items-center    font-[400]`} >
          <span
            className={`w-3 h-3 rounded-full  ${
              state == 0
                ? " bg-main"
                : state == 1
                ? " bg-[#F42929] "
                : state == 2
                ? " bg-[#FF8125] ":" bg-[#29D7FD] "
            }    `}
          ></span>{" "}
          <h1>
            {state == 0 ? t('Stats.PB') : state == 1 ? t('Stats.TU') : state == 2 ? t('Stats.PB') :t('Stats.RB')} 
          </h1> 
        </span>
 
     </div>
       

        <ReactApexChart
          options={options}
          series={options.series}
          type="area"
          height={"100%"}
          width={"102%"}
        />
      </div>
      <span className=" w-full justify-center items-center pt-[.7rem]  flex flex-row">
        {index.map((i)=> (
         <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 7 7" fill="none">
  <circle cx="3.36435" cy="3.604" r="3" fill= { i == state ?  "#06C671" : '#F0EFEF' }/> 
       </svg>
        ) ) }
      </span>
    </div>
  );
};

export default BillingChart;
