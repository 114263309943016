 import adidas from '../../assest/adidas.png'
 import nike from '../../assest/nike.png'
 import carrefour from '../../assest/carrefour.png'
 import gym from '../../assest/gym.png'
 import fila from '../../assest/fila.jpg'

 import nikes from '../../assest/nikes.jpg'
 import nikes3 from '../../assest/nikes3.jpg'
 import nikes2 from '../../assest/nikes2.jpg'

 import adidass from '../../assest/adidass.jpg'
 import adidass3 from '../../assest/adidass3.jpg'
 import adidass2 from '../../assest/adidass2.jpg'
 import adidass4 from '../../assest/adidass4.jpg'


 import carrefours from '../../assest/carrefours.jpg'
 import carrefours1 from '../../assest/carrefours1.jpg'

 import goldgym from '../../assest/goldgyms.jpg'

 import filas from '../../assest/filas.jpg'
 

 
 export  const  Sdata = [
    {
        id:1,
        img:adidas,
        text:'Addidas',
        story:[
            {img:adidass },
            {img:adidass3 },
            {img:adidass2 },
            {img:adidass4 },

         
        ],
        new:true,
        link:'https://www.adidas.com/us'
    },
    {
        id:2,
        img:nike,
        text:'Nike',
        story:[
            {img:nikes3 },
           
            {img:nikes },
             
            {img:nikes2 },
        ],
         link:'https://www.nike.com/'
    },{
        id:3,
        img:carrefour,
        text:'Carrefour',
        story:[
            {img:carrefours },
            {img:carrefours1 },
     
        ],
        link:'https://www.carrefour.fr/'
    },{
        id:4,
        img:gym,
        text:'gold gym',
        story:[
            {img:goldgym },
          
        ]
        ,
        link:'https://www.goldsgym.com/'
    },{
        id:5,
        img:fila,
        text:'Fila',
        story:[
            {img:filas },
             
        ],
        link:'https://www.fila.com/'
    },
    
 ]