import React from 'react';
import ReactDOM from 'react-dom/client';
import 'tailwindcss/tailwind.css'
import "./index.css";
import i18n from './i18n';
import { OpenCvProvider } from 'opencv-react';
import Themeprovider from './context/Themecontexte';
import "@fontsource/cairo"; // Defaults to weight 400
import "@fontsource/cairo/400.css"; // Specify weight
import Userprovider from './context/Usercontexte';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <OpenCvProvider>
  <Themeprovider>
  <Userprovider>
  <React.StrictMode>
  
  <App />
 
  </React.StrictMode>
  </Userprovider>
  </Themeprovider>

  </OpenCvProvider>
);




serviceWorkerRegistration.register();
 
reportWebVitals();
