import React, { useContext, useEffect, useState } from "react";
import { themecontext } from "../../context/Themecontexte";
import colseBtn from "../../assest/CloseBtn.svg";
import SearchIcon from "@mui/icons-material/Search";
import EmailReceipts from "./EmailReceipt";
import { addNewItemID } from "../../Functions/addNewItemID";
import { checkAndAddReceiptsToDB } from "../../Functions/AddReceptTotheLocalDB";
import { Usercontext } from "../../context/Usercontexte";
import '../../App.css'
import LoadingBtn from "../LoadingBtn";
function ConfirmReceipts({ data ,setConfirmIsOpen }) {
  const { theme} = useContext(themecontext);
 const {setMassage , setReceiptes ,setHighlightedItems } = useContext(Usercontext)
  const [ EachData,setEachData] = useState(null)

  const [masterCheckbox, setMasterCheckbox] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});

  
console.log(checkboxStates)
useEffect(() => {
  // Assuming EachData is an array of objects with unique identifiers
  EachData?.forEach((data) => {
    // Initialize checkbox state for each data item
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [data.id]: true, // Assuming each data item has a unique identifier, adjust accordingly
    }));
  });
}, [EachData]);


const handleMasterCheckboxChange = () => {
  setMasterCheckbox(!masterCheckbox);
  setCheckboxStates((prevStates) => {
    const updatedStates = {};
    Object.keys(prevStates).forEach((id) => {
      updatedStates[id] = !masterCheckbox;
    });
    return updatedStates;
  });
};


useEffect(()=>{
  const allCheckboxesChecked = Object.values(checkboxStates).every(Boolean);
  if(allCheckboxesChecked){
    setMasterCheckbox(true)
  }else{
    setMasterCheckbox(false)
  }
},[checkboxStates] )

  const handleCheckboxChange = (checkboxName) => (event) => {
    const { checked } = event.target;
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxName]: checked,
    }));
    console.log(checkboxStates)
  };

 
// Function to extract all data from all messages in the JSON data
function extractAllData(jsonData) {
  const extractedData = jsonData.flatMap(message => {
    const messageObj = message[0];
    if (messageObj) {
      return {
        id: messageObj.message_id,
        snippet: messageObj.snippet,
        body: messageObj.body,
        from: messageObj.from,
        date: messageObj.date,
        subject: messageObj.subject
      };
    }
    
    return null; // or handle missing data
  });

  return extractedData.filter(data => data !== null); // Remove any null values
}

useEffect(()=>{
  // Call the function to get the array of extracted data
  const allDataArray = extractAllData(data.data);
  setEachData(allDataArray)
},[] )
 

function generateUniqueId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}


const ConfirmSelectedData = () => {
  setIsLoading(true)
  const selectedData = EachData.filter((data) => checkboxStates[data.id]);
  selectedData.forEach((item)=> {
  
      const receiptData = {
        id: generateUniqueId(),
        source:data.source,
        data:item,
        file_type:'email'
      };
      checkAndAddReceiptsToDB(
        receiptData,
        setMassage,
        addNewItemID,
        setReceiptes,
        setHighlightedItems
      );
  })
  setTimeout(() => {
    setConfirmIsOpen(false)
  }, 1000);
};
 
  return (
    <>
      <div
        className={` px-[1.2rem] pt-[1.7rem]  w-full h-full fixed top-0 right-0  z-40 ${
          !theme ? " bg-[#212121]  " : " bg-white"
        } `}
      >
        <img onClick={()=>setConfirmIsOpen(false) } className=" absolute top-5 right-4" src={colseBtn} alt="" />
        <h1 className=" text-[1.1rem] font-semibold ">My Receipts</h1>
        <p className="  font-medium  py-4 text-sec text-[.9rem] ">
          Confirm the email(s) containing the receipt(s) you want to import.
        </p>
        <span className=" relative">
          <SearchIcon className=" text-gray-400 absolute left-3 top-1" />
          <input
            placeholder="Search for emails here"
            className=" px-[2.5rem] w-full h-[3.5rem] border-solid border-[1px] rounded-md  bg-[#F8F8F8] bg-opacity-40 "
            type="text"
          />
        </span>

        <span className="  text-sm font-semibold flex flex-row gap-2 pt-6 pb-3 border-solid border-b-[1px] items-center ">
          <input
          checked={masterCheckbox}
          onChange={handleMasterCheckboxChange}
            className="  h-5 w-5   "
            type="checkbox"
            name="selectall"
            id="selectall"
          />
          <label htmlFor="selectall">Select All</label>
        </span>

        <div className=" pt-[1.6rem] h-full pb-[19rem] my-custom-scrollbar pr-2   overflow-y-auto w-full overflow-x-hidden flex flex-col gap-2">
        {EachData?.map((item, index) => (
            <EmailReceipts 
            key={index}
            checkboxId={item?.id}
            checkboxStates={checkboxStates}
            handleCheckboxChange={handleCheckboxChange}
            date={item.date}
            subject={item.subject}
            body={item.body}
            from={item.from}
            />
              ))}
              
              
        </div>
        <div className="absolute bottom-0  h-20 right-0 flex w-full bg-white justify-center items-center">
        <button onClick={ConfirmSelectedData} className="   text-lg text-white  font-semibold w-[90%] h-14 rounded-md   relative  bg-main ">Confirm
         <LoadingBtn bg={'bg-main'} color={'white'}  isLoading={IsLoading} small={true} />
        </button>
        </div>
      </div>
     </>
  );
}

export default ConfirmReceipts;
