import { React, useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { themecontext } from "../../context/Themecontexte";
const TrendChart = ({DATA2, DATA1, state ,Period, colors ,filteredData}) => {
const {theme} = useContext(themecontext)
const [PDatachart, setPDatachart ] = useState()
const [DDatachart, setDDatachart ] = useState()
const [TDatachart, setTDatachart ] = useState()
const [STrendsData, setSTrendsData ] = useState()

useEffect(()=>{

  const processRawData = (data , state) => {
    const processedData = [];
   const time = data?.time
    const Data = data?.data
for (let i = 0; i < Data?.length; i++) {
       const entry = Data[i];
       const Time = time[i];

        const entryDate = new Date(Time);
          let timeUnitValue;
          if (Period === 'Last year' || Period === 'This year' ) {
            timeUnitValue = entryDate.toLocaleString('en-us', {  month:'short' });
          } else if (  Period === 'Today' ) {
            timeUnitValue = entryDate.toLocaleString('en-us', {  hour: 'numeric', minute: 'numeric'  });
          } 
          else {
            timeUnitValue = entryDate.toLocaleString('en-us', {  day:'2-digit' });
          }
          // Create the object in the required format
          const dataObject = { x: timeUnitValue, y: entry};

          // Push the object to the processedData array
          processedData.push(dataObject);
  
}
    
if(state == 'DATA1' ){
  setDDatachart(processedData);
} else if (state == 'DATA2' ){
  setPDatachart(processedData);
 
}
    

  };

  // Call the processing function
  processRawData(DATA2 , 'DATA2');
  processRawData(DATA1  , 'DATA1');

},[DATA2,DATA1 ,Period] )



useEffect(()=>{
  const processedData = [];
 

  for (let i = 0; i < filteredData?.length; i++) {
    const entry = filteredData[i];

  // Convert the object into an array for rendering
 
     const entryDate = new Date(entry?.created_at);
       let timeUnitValue;
       if (Period === 'Last year' || Period === 'This year' ) {
         timeUnitValue = entryDate.toLocaleString('en-us', {  month:'short' });
       } else if (  Period === 'Today' ) {
         timeUnitValue = entryDate.toLocaleString('en-us', {  hour: 'numeric', minute: 'numeric'  });
       } 
       else {
         timeUnitValue = entryDate.toLocaleString('en-us', {  day:'2-digit' });
       }
       // Create the object in the required format
       const dataObject = { x: timeUnitValue, y: entry?.json?.total};

       // Push the object to the processedData array
       processedData.push(dataObject);
       setTDatachart(processedData)
 }
 
  
}, [Period]) 


 

 
  
  const options = {
    animations: {
      enabled: true, // Enable animations
      dynamicAnimation: {
        speed: 50, // Animation speed in milliseconds
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      foreColor: theme ?  "#333" : "#ffefef",

      fontFamily: "Archivo",
      height: 258,
      id: "Iz30P",
      toolbar: {
        show: false,
      },
      type: "area",
      width: 480,
      fontUrl: null,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
        // Add other bar plot options...
      },
      bubble: {
        zScaling: true,
        // Add other bubble plot options...
      },
      treemap: {
        dataLabels: {
          format: "scale",
          // Add other treemap plot options...
        },
      },
      radialBar: {
        hollow: {
          background: "#fff",
          // Add other radial bar plot options...
        },
      
      },
      pie: {
        donut: {
          labels: {
            name: {},
            value: {},
            total: {},
            // Add other donut label options...
          },
        },
      },
      // Add other plot options...
    },
    colors: ["#00D979", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
    dataLabels: {
      enabled: false,
      offsetX: -7,
      offsetY: -5,
      style: {
        fontWeight: 400,
      },
      dropShadow: {
        blur: 2,
      },
      // Add other data label options...
    },
    fill: {},
    grid: {
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true,
        },
      },
      column: {},
      padding: {
        top: -11,
        right: 0,
        left: -10,
      },
      // Add other grid options...
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      floating: true,
      fontSize: 14,
      offsetY: 0,
      markers: {
        width: 29,
        height: 30,
        strokeWidth: 10,
        radius: 49,
      },
      itemMargin: {
        vertical: 0,
      },
      // Add other legend options...
    },
    markers: {
      size: 6,
      strokeColors: "#40FF26",
      strokeWidth: 0,
      fillOpacity: 0.7,
      hover: {
        size: 4,
        sizeOffset: 6,
      },
      // Add other marker options...
    },
    series: state ?  [
        {
          name: "Area",
          data: PDatachart?.slice(0,14),
          color: colors[0] , // Color for series 1
          
          
        },
        {
          name: "Area",
          data:  DDatachart?.slice(0,14),
          color:  colors[1], // Color for series 1
          
        },
      ] : [
         {
          name: "Area",
          data: TDatachart?.slice(0,14),
          color: "#ebc041", // Color for series 1
          
        }
      ] ,

    stroke: {
      lineCap: "round",
      width: 4,
      curve: 'smooth',
      fill: {
        type: "solid",
        opacity: 0.85,
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          inverseColors: false,
          opacityFrom: 0.65,
          opacityTo: 0.5,
          stops: [0, 100, 100],
          colorStops: [],
        },
      },
      // Add other stroke options...
    },
    xaxis: {
        type: "numeric",
        offsetY: -5,
        labels: {
          trim: true,
          style: {
            fontWeight: 300,
          },
        },
      group: {
        groups: [],
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 400,
          cssClass: "",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: "dataPoints",
      title: {
        style: {
          fontWeight: 700,
        },
      },
      // Add other x-axis options...
    },
    yaxis: {
      offsetY: 10,
      tickAmount: 6,
      labels: {
        style: {
          colors: [null, null, null, null, null, null, null],
          fontSize: 10,
          fontWeight: 300,
        },
      },
    }
    // Add other top-level options...
  };

  return (
    <div className=" relative w-full h-full  mt-[.7rem] ">
      <div className="  w-full flex  pb-[-1rem]  rounded-[.32rem] pt-[.5rem] pl-[.4rem] pr-[1.5rem] border-solid border-[1px]    flex-col   h-[250px] ">
     

        <ReactApexChart
          options={options}
          series={options.series}
          type="line"
          height={"100%"}
          width={"102%"}
        />
        
      </div>
 
    </div>
  );
};

export default TrendChart;
