import { React, useContext, useState , useEffect } from "react";
import { themecontext } from "../../context/Themecontexte";
import LoadingBtn from "../LoadingBtn";
import closeBtn from "../../assest/CloseBtn.svg";
import GmailImg from "../../assest/gmail.svg";
import OutlookImg from "../../assest/outLook.png";
import phone from "../../assest/phone.svg";
import { linkEmailWithAccount } from "../../API/importEmails";
import Cookies from "js-cookie";

function Social({ isOpen, setIsOpen, text, integration ,EmailReceipts }) {
  const { theme } = useContext(themecontext);
  const [GmailIsLoading, setGmailIsLoading] = useState(false);
  const [OutlookIsLoading, setOutlookIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
 


useEffect(()=>{
if(progress == 100 ){
  setTimeout(() => {
    setIsOpen(false)
  }, 1000);
}
},[progress] )

  useEffect(() => {
    let interval;
    const simulateProgress = () => {
        setTimeout(() => {
        if (EmailReceipts) {
          setProgress(100);
          clearInterval(interval); // Stop the interval when progress is completed
        }
      }, 4000);
      // Simulate progress by increasing it every second
      interval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 100));
      }, 1000);
  
      // Simulate completing progress after some time (e.g., 5 seconds)
    
    };
  
    // Start simulating progress
    simulateProgress();
  
    // Clean up the interval when the component unmounts or EmailReceipts changes
    return () => clearInterval(interval);
  
  }, [EmailReceipts]);
  


  async function LinkGmail() {
    setGmailIsLoading(true);
    const token = Cookies.get("access_token");
    if (token) {
      const res = await linkEmailWithAccount(token, "gmail");
      console.log(res);
      if (res) {
        window.location.href = res;
      }
    }
  }

  async function LinkOutlook() {
    setOutlookIsLoading(true);
    const token = Cookies.get("access_token");
    if (token) {
      const res = await linkEmailWithAccount(token, "outlook");
      console.log(res);
      if (res) {
        window.location.href = res;
      }
    }
  }

  return (
    <div
      className={` duration-300  ${
        isOpen ? "top-[50%]" : " top-[-100%] -z-10 "
      }  flex  z-[120] fixed h-full w-full relateve `}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`  flex  fixed h-full w-full absulet ${
          isOpen ? " opacity-50 top-0  " : "opacity-0  top-[-100%] -z-10  "
        }  right-0 bg-black `}
      ></div>

      <div
        className={` ${
          isOpen
            ? "  z-30  opacity-100 top-0 "
            : " opacity-0  hidden   -top-[100%] -z-10    "
        }   duration-150     transform      flex   translate-x-[-50%]   translate-y-[-50%]  top-[50%]  h-[22rem]    left-[50%]   fixed  w-[80%]      flex-col items-center justify-between  pt-[1.6rem] pb-[3.5rem] px-[.74rem]    rounded-[.31rem]  ${
          theme ? " bg-white" : " bg-[#212121]"
        }   `}
      >
        <img
          onClick={() => setIsOpen(!isOpen)}
          className="  absolute top-4  w-[1.4rem]  right-4 cursor-pointer"
          src={closeBtn}
          alt=""
        />

        {integration && (
          <div className=" flex text-center pt-9 flex-col items-center h-full  justify-around w-full">
            <div className=" flex flex-row items-center  justify-around w-full">
              <img
                className= {` ${progress == 100 ? ' opacity-0':' opacity-100' } duration-100 w-[2.2rem] h-[2.2rem]  `} 
                src={integration == "gmail" ? GmailImg : OutlookImg}
                alt=""
              />
              <img className=" h-[4rem]  " src={phone} alt="" />
            </div>
            <span className="relative rounded-full w-[70%] h-3 bg-gray-200">
              <span
                style={{ width: `${progress}%` }}
                className="h-full bg-main absolute top-0 left-0 rounded-full transition-width duration-500"
              ></span>
            </span>
            <h1 className=" text-[.9rem] text-sec font-bold w-[80%]  ">
             {progress != 100 ? ' Please wait while we import your emails. This may take a moment.':'Congratulations! Your emails have been imported successfully'}
            </h1>
          </div>
        )}

        {!integration && (
          <div className=" absolute top-0 w-full flex-col items-center    flex  right-0     ">
            <div className=" pt-12 flex flex-col gap-4 w-full justify-center items-center">
              <h1 className=" text-[1.1rem] font-bold ">
                Choose Email Provider
              </h1>
              <p className=" text-center text-sec text-[] w-[80%]  ">{text}</p>
            </div>
            <div className=" pt-[1.4rem] w-full flex gap-2 flex-col items-center ">
              <div
                onClick={LinkGmail}
                className=" relative h-16  active:border-main  items-center px-3 flex flex-row  gap-2   border-solid  border-[1px] rounded-md  w-[80%] "
              >
                <img
                  className=" h-[1.8rem] w-[1.8rem] "
                  src={GmailImg}
                  alt=""
                />{" "}
                Gmail
                <LoadingBtn
                  isLoading={GmailIsLoading}
                  small={true}
                  bg={theme ? "bg-white" : "bg-[#212121] "}
                  color={theme ? "#000" : "#fff"}
                />
              </div>
              <div
                onClick={LinkOutlook}
                className=" relative h-16  active:border-main  items-center px-3 flex flex-row  gap-2   border-solid border-[1px] rounded-md  w-[80%] "
              >
                <img
                  className=" h-[1.8rem] w-[1.8rem] "
                  src={OutlookImg}
                  alt=""
                />{" "}
                Outlook
                <LoadingBtn
                  isLoading={OutlookIsLoading}
                  small={true}
                  bg={theme ? "bg-white" : "bg-[#212121] "}
                  color={theme ? "#000" : "#fff"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Social;
