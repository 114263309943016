import { React, useEffect, useState ,  useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";

const MyChart = ({ settotal, isChartChanged   ,setTotals, state ,filteredData,Period}) => {
const {theme} = useContext(themecontext)
  const [chartData, setChartData] = useState([]);
  const [HchartData,setHchartData]=useState([])
  const [LchartData,setLchartData]=useState([])
     const [AChartData,setAChartData]=useState([])
  const [Hdata,setHdata]=useState()
  const [Tdata,setTdata]=useState()

   const [Ldata,setLdata]=useState() 
   const [Avrge,setAvrge]=useState()

   
 const {t, i18n} = useTranslation()
 var isArabic = i18n.language.startsWith('ar');

 

  useEffect(() => {
    function getPeriod(timestamp  , Period) {
      const date = new Date(timestamp);
      if(Period === 'Yesterday' || Period === 'Today'){

        return date.getHours();
      }
      else if (Period === 'Last year' || Period === 'This year'){
        return date.getMonth();
      }
       else {
         return date.getDate();
       }
    }
 
     const groupedData = {};
     const TotalData = {};

    filteredData?.forEach(entry => {
      const hour = getPeriod(entry.created_at ,Period);
      if (!TotalData[hour]) {
        TotalData[hour] = {
          total: entry?.json?.total,
          date: entry.created_at, // Add createdAt property
        };
      } else {
        // If the period is already in TotalData, add the total and update the createdAt
        TotalData[hour].total += entry?.json?.total;
        TotalData[hour].date = entry.created_at; // Update createdAt property
      }
      
    
      if (!groupedData[hour]) {
        groupedData[hour] = { total: [], highest: entry, lowest: entry };
      } else {
        groupedData[hour]?.total.push(entry?.json?.total);
    
        if (entry.json?.total > groupedData[hour].highest?.json?.total) {
          groupedData[hour].highest = entry;
        }
        if (entry.json?.total < groupedData[hour].lowest?.json?.total) {
          groupedData[hour].lowest = entry;
        }
      }
    });
    const totalresult = Object.values(TotalData)

    const result = Object.values(groupedData).map(hourData => {
      const totalTransactions = hourData?.total.reduce((acc, val) => acc + val, 0);
      const averageTransactions = totalTransactions / hourData?.total?.length;
    
      return {
        hour: hourData.highest.created_at,
        averageTransactions: averageTransactions.toFixed(0), 
        highest: hourData.highest,
        lowest: hourData.lowest,
      };
    });
 
 
    setHdata(result.map((i)=> (i.highest)))
    setLdata(result.map((i)=> (i.lowest)))
    setAvrge({data: result.map((i)=> (i.averageTransactions)), time: result.map((i)=> (i.hour)) } )
    setTdata({data: totalresult.map((i)=> (i.total)), time: totalresult.map((i)=> (i.date)) } )

 
 
 const highestTotals = result.map((i) => i.highest?.json?.total);
 const lowTotals = result.map((i) => i.lowest?.json?.total);

 

// Find the highest and lowest values
var numericHTotals = highestTotals.map(value => (isNaN(value) ? 0 : value));
var numericLTotals = lowTotals.map(value => (isNaN(value) ? 0 : value));

// Find max and min values
var maxTotal = numericHTotals.length > 0 ? Math.max(...numericHTotals) : 0;
var minTotal = numericLTotals.length > 0 ? Math.min(...numericLTotals) : 0;

// Calculate the average
var averageTotal = numericHTotals.length > 0
  ? numericHTotals.reduce((sum, total) => sum + total, 0) / numericHTotals.length
  : 0;

 
  setTotals({
    average:averageTotal.toFixed(0),
  lowset:minTotal.toFixed(0),
  highest:maxTotal.toFixed(0),
  });
 
  }, [Period ,state]);



useEffect(()=>{
  

  const processRawData = (data , state) => {
    const processedData = [];
    let totalAmount = 0; // Declare totalAmount before the loop
   const time = data?.time
    const Data = data?.data
    if(state == 'Avrge' ||  state == 'total'){
      for (let i = 0; i <Data?.length; i++) {
       const entry = Data[i];
       const entryTime = time[i];


        const entryDate = new Date(entryTime);
          let timeUnitValue;
          if (Period === 'Last year' || Period === 'This year' ) {
            timeUnitValue = entryDate.toLocaleString('en-us', {  month:'short' });
          } else if (  Period === 'Today' ) {
            timeUnitValue = entryDate.toLocaleString('en-us', {  hour: 'numeric', minute: 'numeric'  });
          } 
          else {
            timeUnitValue = entryDate.toLocaleString('en-us', {  day:'2-digit' });
          }
          // Create the object in the required format
          const dataObject = { x: timeUnitValue, y: entry};

          // Push the object to the processedData array
          processedData.push(dataObject);
          totalAmount +=entry || 0;
    }
}else{
  for (let i = 0; i < data?.length; i++) {
    const entry = data[i];
 

     const entryDate = new Date(entry?.created_at);
       let timeUnitValue;
       if (Period === 'Last year' || Period === 'This year' ) {
         timeUnitValue = entryDate.toLocaleString('en-us', {  month:'short' });
       } else if (  Period === 'Today' ) {
         timeUnitValue = entryDate.toLocaleString('en-us', {  hour: 'numeric', minute: 'numeric'  });
       } 
       else {
         timeUnitValue = entryDate.toLocaleString('en-us', {  day:'2-digit' });
       }
       // Create the object in the required format
       const dataObject = { x: timeUnitValue, y: entry?.json?.total};

       // Push the object to the processedData array
       processedData.push(dataObject);
       
 }
      }
     



if(state == 'highest' ){
setHchartData(processedData);
} else if (state == 'lowest' ){
 setLchartData(processedData);
}else if (state == 'total' ){
   setChartData(processedData);
   settotal(totalAmount);
}else{
setAChartData(processedData)
}

 

  };

  // Call the processing function
  processRawData(Ldata  , 'lowest');
  processRawData(Hdata , 'highest');
    processRawData(Tdata , 'total');
    processRawData(Avrge , 'Avrge');
    
  
   

  
  
},[  Period, isChartChanged, state, Ldata, Hdata, Avrge]  )


  const index = [0, 1, 2, 3];

  const options = {
    chart: {
      animations: {
        enabled: true, // Enable animations
        dynamicAnimation: {
          speed: 50, // Animation speed in milliseconds
        },
      },
      foreColor: theme ?  "#333" : "#ffefef",
      fontFamily: "Archivo",
      height: 258,
      id: "Iz30P",
      toolbar: {
        show: false,
      },
      type: "area",
      width: 480,
      fontUrl: null,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        isFunnel: false,
        isFunnel3d: true,
        dataLabels: {
          total: {
            enabled: false,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#373d3f",
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
      },
      bubble: {
        zScaling: true,
      },
      treemap: {
        dataLabels: {
          format: "scale",
        },
      },
      radialBar: {
        hollow: {
          background: "#fff",
        },
        dataLabels: {
          name: {},
          value: {},
          total: {},
        },
      },
      pie: {
        donut: {
          labels: {
            name: {},
            value: {},
            total: {},
          },
        },
      },
    },

    dataLabels: {
      enabled: false,
      offsetX: -7,
      offsetY: -4,
      style: {
        fontWeight: 400,
      },
      dropShadow: {
        blur: 2,
      },
    },
    fill: {},
    grid: {
      show: false,
      strokeDashArray: 8,
      xaxis: {
        lines: {
          show: true,
        },
      },
      column: {},
      padding: {
        top: -13,
        right: 0,
        left: -13,
      },
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      floating: true,
      fontSize: 14,
      offsetY: 0,
      markers: {
        width: 29,
        height: 30,
        strokeWidth: 10,
        radius: 49,
      },
      itemMargin: {
        vertical: 0,
      },
    },
    markers: {
      size: 6,
      strokeColors: "#40FF26",
      strokeWidth: 0,
      fillOpacity: 0.7,
      hover: {
        size: 4,
        sizeOffset: 6,
      },
    },
    series:
      state == 2 || state == 3
        ? [
            {
              name: "Area",
              data: HchartData?.slice(0,14) ,
              color: "#fc6464", // Color for series 1
              type: state === 2 ? "area" : "line",
            },
            {
              name: "series-2",
              data: LchartData?.slice(0,14) ,
              color: "#00D979", // Color for series 2
              type: state === 3 ? "area" : "line",
            },
          ]
        : state == 0
        ? [
            {
              name: "Area",
              data: chartData?.slice(0,14) ,
              color: "#00D979", // Color for series 1
              type: state === 0 ? "area" : "line",
            },
          ]
        : [
            {
              name: "Area",
              data: AChartData?.slice(0,14) ,
              color: "#ebc041", // Color for series 1
              type: state === 1 ? "area" : "line",
            },
          ],

    stroke: {
      lineCap: "round",
      
      width: 4,
      fill: {
        type: "solid",
        opacity: 0.85,
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          inverseColors: false,
          opacityFrom: 0.65,
          opacityTo: 0.5,
          stops: [0, 100, 100],
          colorStops: [],
        },
      },
    },
    xaxis: {
      type: "numeric",
      offsetY: -5,
      labels: {
        trim: true,
        style: {
          fontWeight: 200,
        },
      },
      group: {
        groups: [],
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 400,
          cssClass: "",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: "dataPoints",
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      offsetY: 10,
      tickAmount: 6,
      labels: {
        style: {
          colors: [null, null, null, null, null, null, null],
          fontSize: 10,
          fontWeight: 200,
        },
      },
      title: {
        style: {},
      },
    },
  };
  return (
    <div className=" relative w-full h-full  mt-[.7rem] ">
      <div className="  w-full flex  pb-[1.3rem]  rounded-[.32rem] pt-[1.7rem] pl-[0rem] pr-[1.8rem] border-solid border-[1px]    flex-col   h-[300px] ">
        <div className=" flex flex-row items-center w-full gap-1 justify-between">
          <span
            className={`w-full  justify-end flex flex-row gap-1 items-center ${
              state >= 2 ? " text-[.94rem]" : "text-[1.1rem]"
            }  font-[400]`}
          >
            <span
              className={`w-3 h-3 rounded-full  ${
                state == 0
                  ? " bg-main"
                  : state == 1
                  ? " bg-yellow-400"
                  : " bg-red-500"
              }    `}
            ></span>{" "}
            <h1>
              {state == 0
                ? t('Stats.TSpending')
                : state == 1
                ? t('Stats.AT')+ ' '+t('Stats.T') 
                : t('Stats.HT')+ ' '+t('Stats.T') }
            </h1>
          </span>

          {state >= 2 && (
            <span
              className={` w-full  justify-end flex flex-row gap-1 ${
                state >= 2 ? " text-[.94rem]" : "text-[1.1rem]"
              }  items-center  font-[400] `}
            >
              <span className={`w-3 h-3 rounded-full bg-main  `}></span>{" "}
              <h1>{t('Stats.LT') +' ' + t('Stats.T')  }</h1>
            </span>
          )}
        </div>

        <ReactApexChart
      
          options={options}
          series={options.series}
          type="area"
          height={"100%"}
          width={"102%"}
        />
      </div>
      <span className=" w-full justify-center items-center pt-[.7rem]  flex flex-row">
        {index.map((i) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 7 7"
            fill="none"
          >
            <circle
              cx="3.36435"
              cy="3.604"
              r="3"
              fill={i == state ? "#06C671" : "#F0EFEF"}
            />
          </svg>
        ))}
      </span>
    </div>
  );
};

export default MyChart;
