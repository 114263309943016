import React, { useEffect, useState , useContext } from "react";
import TopBar from "../compenent/TopBar";
import AddIcon from '@mui/icons-material/Add';
import { Sdata } from "../assest/data/Sdata";
import axios from "axios";
import Cookies from "js-cookie";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useLocation } from "react-router-dom";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AddCard from "../compenent/CradComponent/AddCard";
import AddCardManully from "../compenent/CradComponent/AddCardManully";
import CardDetails from "../compenent/CradComponent/CardDetails";
import NoCards from "../compenent/CradComponent/NoCards";
import SearchIcon from '@mui/icons-material/Search';
import { Usercontext } from "../context/Usercontexte";
 import Card from "../compenent/CradComponent/Card";
 import { motion } from "framer-motion";
 import { useTranslation } from "react-i18next";
import Story from "../compenent/CradComponent/Story";
import CodeBarScanner from "../compenent/CradComponent/QRCodeScanner";
 
function Cards() {
const {pathname} = useLocation();
const [ SelectedStory, setSelectedStory] = useState()
const [ StoryOpen, setStoryOpen] = useState(false)
const {card  ,setIsCardChange ,IsCardChange , User} = useContext(Usercontext)
const {t , i18n} = useTranslation()
 var isArabic = i18n.language.startsWith('ar');


function getStory(id){
  setSelectedStory(Sdata.find((i)=>i.id == id)) 
}

 // conifg for animation 
const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,

    transition: {
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
};
  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 5,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 7,
    },
    // when window width is >= 768px
  };
 
  return (
   <>
   { pathname == '/Cards' &&
   
   <div className="   w-full   flex  flex-col  px-[1.2rem]    relative    ">
      <div className=" w-full pt-[1.4rem]  flex flex-row justify-between">
        <TopBar text={t('topbar.Cardtoptitle')} />   <SearchIcon/>
      </div>
    { StoryOpen && <Story stories={SelectedStory} setStoryOpen={setStoryOpen} />}
  
   {  
       card?.length <= 0 || !User  ?   <NoCards/> :  <div>
      <div className=" pt-2">
        <Swiper
          breakpoints={breakpoints}
          centeredSlides={false}
          spaceBetween={20}
          grabCursor={true}
          className="mySwiper   flex-row w-full h-full     items-center justify-center   flex  pt-[1.5rem]  "
        >
          {Sdata.map((item) => (
            <SwiperSlide >
              <div onClick={()=> {getStory(item.id) ; setStoryOpen(true)} } className=  { ` ${ item.new ? 'border-green-400 border-[1.3px] ' :'' }   relative border-solid w-[4.2rem] h-[4.2rem]  rounded-[50%] border-[1px]`}>
                <img
                  className=  {` rounded-[50%]  absolute top-0 right-0 w-full h-full object-cover    p-[0.15rem]   z-30`} 
                  src={item.img}
                  alt=""
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className=" gap-1 w-full flex  flex-col pt-[1.3rem] ">
        <h1 className=" text-[1.1rem] font-[600]  "> {t('card.title')} </h1>
        <p className=" pb-[.9rem] text-sm text-[500] text-sec ">{t('card.p', {num:card?.length})} </p>
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
        className=" grid grid-cols-2  h-full gap-2">
           {card?.map((i)=> (
           <Card Sname={i?.store_name} id={i?.id} img={i?.front_image} />
        ))}
        </motion.div>
       
      </div>
      
      
    </div>}
<Link to={'/Cards/addcart'}  className="   p-3 px-4  fixed   bottom-[10%] flex flex-row gap-2 items-center justify-center  right-5 bg-main  text-sm text-white rounded-md ">
     <AddIcon/> {t('card.addC')} 
      </Link>
     </div>
     
     }
{pathname == '/Cards/addcartmanully' &&  <AddCardManully IsCardChange={IsCardChange} setIsCardChange={setIsCardChange}/> }
{pathname == '/Cards/addcart' && <AddCard    /> }
{pathname == '/Cards/scannBarCode' &&  <CodeBarScanner /> }
{pathname == '/Cards/CardDetails' && <CardDetails    /> }



</>
  );
}

export default Cards;
