import LoadingBtn from "../LoadingBtn";


const PDFViewer = ({receipt ,onclick }) => {
 return (
 <div className=" z-50 p-4 fixed w-full py-14 h-full top-0 right-0 bg-gray-900 flex  justify-center items-center">
  <span onClick={()=> onclick(false) } className="  z-50   absolute top-3 right-3  " >
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.63856 0.364371C6.73242 0.0294219 9.8547 0.0294219 12.9486 0.364371C14.6616 0.551899 16.0436 1.86948 16.2446 3.54844C16.6115 6.613 16.6115 9.70889 16.2446 12.7734C16.1428 13.5893 15.762 14.3476 15.1637 14.9256C14.5655 15.5035 13.7849 15.8671 12.9486 15.9575C9.85476 16.2935 6.73236 16.2935 3.63856 15.9575C2.80221 15.8671 2.02164 15.5035 1.42338 14.9256C0.825128 14.3476 0.444272 13.5893 0.34256 12.7734C-0.0243429 9.70921 -0.0243429 6.61365 0.34256 3.54942C0.444228 2.73374 0.824939 1.9755 1.42299 1.39758C2.02105 0.819658 2.80139 0.455932 3.63756 0.365348L3.63856 0.364371ZM4.76356 4.71365C4.90419 4.57647 5.09481 4.49942 5.29356 4.49942C5.49231 4.49942 5.68293 4.57647 5.82356 4.71365L8.29356 7.12612L10.7636 4.71365C10.8322 4.64168 10.915 4.58396 11.007 4.54392C11.099 4.50388 11.1983 4.48235 11.299 4.48062C11.3997 4.47888 11.4998 4.49698 11.5932 4.53382C11.6865 4.57066 11.7714 4.6255 11.8426 4.69506C11.9138 4.76462 11.97 4.84747 12.0077 4.93869C12.0454 5.0299 12.0639 5.1276 12.0622 5.22596C12.0604 5.32431 12.0383 5.42131 11.9973 5.51117C11.9563 5.60103 11.8972 5.6819 11.8236 5.74896L9.35356 8.16143L11.8236 10.5739C11.956 10.7128 12.0282 10.8964 12.0247 11.0862C12.0213 11.276 11.9426 11.457 11.8052 11.5913C11.6678 11.7255 11.4824 11.8024 11.2881 11.8057C11.0938 11.809 10.9057 11.7386 10.7636 11.6092L8.29356 9.19674L5.82356 11.6092C5.7549 11.6812 5.6721 11.7389 5.5801 11.7789C5.4881 11.819 5.38878 11.8405 5.28808 11.8422C5.18738 11.844 5.08735 11.8259 4.99396 11.789C4.90057 11.7522 4.81574 11.6974 4.74452 11.6278C4.6733 11.5582 4.61716 11.4754 4.57944 11.3842C4.54172 11.293 4.52319 11.1953 4.52497 11.0969C4.52674 10.9985 4.54879 10.9015 4.58978 10.8117C4.63077 10.7218 4.68987 10.641 4.76356 10.5739L7.23356 8.16143L4.76356 5.74896C4.62311 5.61161 4.54422 5.42543 4.54422 5.23131C4.54422 5.03719 4.62311 4.851 4.76356 4.71365Z" fill="white"/>
</svg>
  </span>
<div className="  absolute z-0 transform translate-x-[50%] top-[50%] right-[50%] h-12 w-12  scale-[5]   ">

  <LoadingBtn isLoading={true}  />
</div>
 <iframe className=" z-10" src={receipt} width={'100%'} height={'100%'}  />
 </div>
 );
};
export default PDFViewer;