import { React, useState , useContext } from "react";
import TopBar from "../compenent/TopBar";
import { SignupSchema } from "../Validation/SignupVal";
import { useFormik } from "formik";
import LoadingBtn from "../compenent/LoadingBtn";
import Cookies from "js-cookie";
import { Usercontext } from "../context/Usercontexte";
import axios from "axios";
import Masseege from "../compenent/masseege";
import { MuiTelInput } from "mui-tel-input";
import { useTranslation } from "react-i18next";
 
import { useNavigate } from "react-router-dom";


function Signup() {
  const [PassVis, setPassVis] = useState(true);
  const {setFirstFetch, setUser, User,setReceiptes, setCard} = useContext(Usercontext);
  const [msg, setMsg] = useState({ text: "", color: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneRequired, setIsPhoneRequired] = useState("");
  const {t , i18n} = useTranslation()
var isArabic = i18n.language.startsWith('ar');

const Navto = useNavigate();



// cheking if the phone number valid
const handleChangePhone = (newValue) => {
  setPhone(newValue);
  if (newValue.length< 8) {
    setIsPhoneRequired('min');
  } else {
    setIsPhoneRequired(null); // Reset the required flag when the phone is valid
  }
};
function onSubmit() {
  if(isPhoneRequired == 'min'){
    return
   }
   // Set loading to true when the form is submitting
   setIsLoading(true);
if(User){
  // geting the user Token from llocalStorge
  const token = Cookies.get("access_token");
  axios
    .put(
      "/api/clients/me",
      {
        email: values.email,
        password: values.password,
        last_name: values.lastname,
        is_anonymous:false,
        first_name: values.firstname,
        phone,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      // Log in after the Siginin Secsess
      axios.post(
        "/api/clients/login",
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const { data } = response;
        setIsLoading(false);
  
        // Set the user data in your app
        Cookies.set("access_token", 'Token ' + data.access_token);
        setUser(data.user);
  
        // Navigate to the account page
        Navto('/Account');
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      }); 
    })
    .catch((error) => {
      console.error("Error:", error);
      setIsLoading(false);
      setMsg({
        text: error.response.data.email
          ? t('Msg.EmailAlreadyUsed')
          : t('Msg.InternalServerError'),
        color: "bg-[#FF1203F5]",
      });
    });
  
}else{


  axios.post('/api/clients/signup', {
    email: values.email,
    password: values.password,
    last_name: values.lastname,
    first_name: values.firstname,
    phone,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Try to log in with the newly created account
    axios.post(
      "/api/clients/login",
      {
        email: values.email,
        password: values.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const { data } = response;
      setIsLoading(false);

      // Set the user data in your app
      Cookies.set("access_token", 'Token ' + data.access_token);
      setUser(data.user);

      // Navigate to the account page
      Navto('/Account');
    })
    .catch((error) => {
      console.error("Error:", error);
      setIsLoading(false);
    });
  })
  .catch(error => {
    console.error('Error:', error);

    // Check for specific error conditions, like duplicate email
    if (error?.response   ) {
      setMsg({
        text: t('Msg.EmailAlreadyUsed'),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
    } else {
      setMsg({
        text: t('Msg.InternalServerError'),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
    }

    // Set loading to false
    setIsLoading(false);
  });
}
}
// inputs validation 
  const {
    values,
    handleBlur,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
 
    },
    validationSchema: SignupSchema,
    onSubmit,
  });

  {
    errors.firstname && touched.firstname && (
      <p className=" pt-1 px-1 text-xs text-red-400"> {errors.firstname} </p>
    );
  }

  return (
    <div className=" px-[1.2rem] pt-[1.8rem] flex flex-col pb-20 ">
      <TopBar text={t('topbar.Stoptitle')} />

      <form className="  py-9" onSubmit={handleSubmit}>
        <div className=" flex flex-col  ">
          <h1 className=" font-medium  pb-2 text-[#373737]  pt-[1.5rem] text-[.9rem] ">
            {t('Signup.Fname')}
          </h1>
          <input
          placeholder={t('Signup.Fname')}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstname}
            className={` ${isArabic ? ' text-right'  :' text-left'}  ${
              errors.firstname && touched.firstname
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[3.2rem] bg-transparent  px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="text"
            name=""
            id="firstname"
          />
          {errors.firstname && touched.firstname && (
            <p className="   pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.firstname}{" "}
            </p>
          )}
        </div>
        <div className=" flex flex-col  ">
          <h1 className="pb-2 text-[#373737] pt-[1.5rem] text-[.9rem] font-medium ">{t('Signup.Lname')}</h1>
          <input
          placeholder={t('Signup.Lname')}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastname}
            className={` ${isArabic ? ' text-right'  :' text-left'}  ${
              errors.lastname && touched.lastname
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[3.2rem] bg-transparent  px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="text"
             
            id="lastname"
          />
          {errors.lastname && touched.lastname && (
            <p className=" pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.lastname}{" "}
            </p>
          )}
        </div>

        <div className=" flex flex-col   ">
          <h1 className="pb-2 text-[#373737]  pt-[1.5rem] text-[.9rem] font-medium  ">{t('Signup.Email')}</h1>
          <input
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            placeholder="example@gmail.com"
            className={` ${isArabic ? ' text-right'  :' text-left'}  ${
              errors.email && touched.email
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[3.2rem] bg-transparent  px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="Email"
            name=""
            id="email"
          />
          {errors.email && touched.email && (
            <p className=" pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.email}{" "}
            </p>
          )}
        </div>
        <div className=" flex flex-col gap-2">
          <h1 className="pb-2 text-[#373737]  pt-[1.5rem] text-[.9rem] font-medium ">
          {t('Signup.Phone')}
          </h1>
          <MuiTelInput
            className={`   `}
            id="phonenumber"
         
            value={phone}
            onClick={()=>setIsPhoneRequired('') }
            name="phonenumber"
            onChange={handleChangePhone}
          />
            {isPhoneRequired == 'min' &&  (
            <p className=" pt-1 px-1 text-xs text-red-400"> phonenumber is a required field (min 8 char) </p>
          )}
        </div>

        <div className=" flex flex-col gap-2 ">
          <h1 className=" text-[#373737]  pt-[1.5rem]  text-[.9rem] font-medium">{t('Signup.psw')} </h1>
          <span className=" relative ">
            <input
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              className={` ${isArabic ? ' text-right'  :' text-left'} ${
                errors.password && touched.password
                  ? " border-[#fc8181] text-red-500  border-[2px]"
                  : ""
              } text-sec w-full h-[3.2rem] bg-transparent   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
              type={PassVis ? "password" : "text"}
              placeholder={t('Signup.writePsw')}
              name=""
              id="password"
            />
            {errors.password && touched.password && (
              <p className=" pt-1 px-1 text-xs text-red-400">
                {" "}
                {errors.password}{" "}
              </p>
            )}
            <span
              onClick={() => setPassVis(!PassVis)}
              className=  {`absolute  ${isArabic ? '  left-4'  :' right-4'}  top-4 `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 17 12"
                fill="none"
              >
                <path
                  d="M8.63413 0.0593017C4.99778 0.0496582 1.88628 2.32208 0.619569 5.53807C1.86921 8.76073 4.9686 11.0496 8.60496 11.0593C12.2413 11.0689 15.3528 8.79648 16.6195 5.5805C15.3699 2.35784 12.2705 0.0689453 8.63413 0.0593017ZM8.60982 9.22594C6.60255 9.22061 4.97782 7.57363 4.98319 5.54964C4.98856 3.52565 6.622 1.88731 8.62927 1.89263C10.6365 1.89795 12.2613 3.54493 12.2559 5.56893C12.2505 7.59292 10.6171 9.23126 8.60982 9.22594ZM8.62538 3.35929C7.41811 3.35609 6.44096 4.33617 6.43773 5.5535C6.4345 6.77083 7.40644 7.75607 8.61371 7.75927C9.82098 7.76248 10.7981 6.7824 10.8014 5.56507C10.8046 4.34774 9.83264 3.36249 8.62538 3.35929Z"
                  fill={!PassVis ? "#06C671" : "#CBCBCB"}
                />
              </svg>
            </span>
          </span>
        </div>
        <div className=" w-full pt-10 ">
          <button
            type="submit"
            className=" relative h-[3.4rem]  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
          >
            {t('topbar.Stoptitle')}
            <LoadingBtn isLoading={isLoading}  bg={'bg-main'} small={true} />
          </button>
        </div>
      </form>
      <Masseege msg={msg} setMsg={setMsg}  />

    </div>
  );
}

export default Signup;
