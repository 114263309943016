import { React, useState, useEffect,useContext } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Barcode from "react-barcode";
import { Usercontext } from "../../context/Usercontexte";
import TopBar from "../TopBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingBtn from "../LoadingBtn";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";


function CardDetails( ) {
   
  const [card, setcard] = useState();
  const [isLoading  , setisLoading ] = useState(false)
  const [Imgcolor, setImgcolor] = useState();
  const [OptionOpen, setOptionOpen] = useState(false);
  const [text, setText] = useState(''); // State for the input text
  const [Gcolor, setGColor] = useState('#FFFFFF');  
  const [deleting , setDeleting]= useState(false)
const {setIsCardChange ,setCard, IsCardChange } = useContext(Usercontext)
const {theme} = useContext(themecontext);

const navto = useNavigate()
const {id} = useParams()  

const {t , i18n} = useTranslation()
var isArabic = i18n.language.startsWith('ar');
  const generateColor = (inputText) => {
    // Simple hashing function
    const hashCode = (str) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    };

    // Convert the hashed value to a color
    const intToRGB = (i) => {
      const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
      return '00000'.substring(0, 6 - c.length) + c;
    };

    const hash = hashCode(inputText);
    const generatedColor = `#${intToRGB(hash)}`;

    return generatedColor;
  };

  

  useEffect(() => {

    const token = Cookies.get("access_token");
  
    axios.get( `/api/clients/fidelity_cards/${id}` ,{
      headers: {
        Authorization: token,
         
      },
    } ).then((response)=>{
      const {data} = response;
      setcard(data)
      setText(data?.store_name);
      const newColor = generateColor(data?.store_name);
      setGColor(newColor);
 
    }  ).catch((error)=>{
      console.error()
    } )
  }, []);



  function deleteCard(id) {
  setisLoading(true)
  const token = Cookies.get("access_token");
  setCard((prevCards) =>  prevCards?.filter((i)=> i.id != id ) )
    axios.delete(`/api/clients/fidelity_cards/${id}`,{
      headers: {
        Authorization: token,
      },
    }  ).then((response)=>{
      setisLoading(false)
      setIsCardChange(!IsCardChange)
      navto('/Cards')
    } ).then((error)=>{
      setisLoading(false)
      navto('/Cards')
    } )
  }

   
 
   
 
  return (
    <div className=" flex flex-col  pb-20  ">
    
     <span className=" flex flex-row py-[1.2rem] px-[1.3rem] justify-between">
        <TopBar text={t('topbar.CardD')} />

        <span onClick={()=> setOptionOpen(!OptionOpen) } className="relative">
          <MoreVertIcon />
          {OptionOpen &&
           <div
            className={`  flex flex-col absolute top-6 right-3 justify-between h-[6rem] w-[7rem] rounded-md shadow-lg    items-start font-medium  ${theme ? ' bg-white':' bg-[#212121] ' } `}
          >
            <Link to={ `/Cards/addcartmanully/${id}` } className=" p-[.8rem] border-solid border-b-[1px] font-[500] border-gray-300 w-full ">
             {t('card.EditC')}
            </Link>

            <h1  onClick={()=> setDeleting(true) } className=" p-[.8rem] font-[500] ">             {t('card.DeleteC')}</h1>
          </div>}
        </span>
      </span>

      <div
        style={ !card?.front_image ? {backgroundColor: Gcolor} : { backgroundImage: `url(${card?.front_image})` , backgroundSize: '250%'} }
        className={` flex flex-col gap-6 bg-no-repeat  items-center justify-start  bg-cover bg-top   text-white  font-[600] text-[1.7rem] p-[1.2rem]    w-full h-[10rem]  `}
      >
        <h1>{card?.store_name} </h1>
       

        <div className="  rounded-xl text-xs   max-w-full p-2  bg-white  border-solid border-[1px]  h-[10rem]  flex items-center justify-center  ">
          <span className='   scale-[.85]   sm:scale-[1.6] '>

      <Barcode value={card?.code}     height={'100%'}  displayValue={false}  width={'3rem'} />
          </span>
    </div>

        
      </div>
      <div className=" flex flex-col  gap-[1rem] pt-[6rem] px-[1.2rem] ">
        <h1 className=" text-[1.1rem]  font-medium ">{t('card.Notes')}</h1>
        <input
          placeholder="Notes"
           value={card?.notes}
          type="text"
          className=" h-[3.7rem] px-[1rem] bg-transparent  w-full rounded-[.32rem] border-solid border-[1px]  "
        />
        <h1 className=" text-[1.1rem]  font-medium">{t('card.CardP')} </h1>

        <div className=" flex flex-row w-full rounded-lg border-solid border-[1px] h-[12rem] justify-center gap-2 items-center px-10  ">
          <img
            className=" rounded-lg w-[9rem] h-[7rem]    object-cover   "
            src={card?.front_image
            }
            alt=""
          />
          <img
            className=" rounded-lg w-[9rem] h-[7rem] border-solid border-[1px]   object-cover   "
            src={card?.back_image}
            alt=""
          />
        </div>
      </div>
{ deleting &&
 <div className=" z-50 fixed w-full h-full top-0 right-0 ">
     <div className=" absolute top-0 right-0 bg-black w-full h-full opacity-30"></div>
      <div className=" w-[70%] px-4 py-2 text-[.95rem] text-center h-[7rem] flex flex-col bg-white shadow-lg fixed top-[40%] transform translate-x-[50%] right-[50%]   rounded-md  ">
          <h1>Are you sure you want to delete this card?</h1>
          <span className=" flex gap-3 w-full flex-row justify-between items-center h-full ">
            <button onClick={ (e)=> {  setDeleting(false)  ;  deleteCard(id)}  } className=" w-full rounded-md h-9  bg-red-400 text-white font-[600] ">Yes</button>
            <button onClick={(e)=> {  setDeleting(false)  ;  e.preventDefault()} } className=" w-full  rounded-md h-9  text-gray-500 border-solid  border-gray-400 border-[1px] ">No</button>

          </span>
      </div>

      </div>}

    {isLoading &&  <div className=" flex items-center z-50 justify-center fixed bg-black opacity-30 w-full h-full">
    <LoadingBtn isLoading={isLoading} />
      </div>}

    </div>
  );
}

export default CardDetails;
