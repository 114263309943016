import {React,useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import 'react-loading-skeleton/dist/skeleton.css' 
import { themecontext } from "../../context/Themecontexte";
function ReceiptsSkeleton({receipts}) {
const {theme} = useContext(themecontext)
const { i18n } = useTranslation()
var isArabic = i18n.language.startsWith('ar');

  return (
 
    Array.from({ length: receipts }, (_, index) => (
        <div key={index} className= {` gap-2  h-[3.8rem]   mt-2  border-gray-100 border-solid border-[1px] rounded-md items-center    flex  ${isArabic ? " flex-row-reverse" : "flex-row"}  justify-between  w-full px-3`} >
       
       <div className=  {`  ${isArabic ? " flex-row-reverse" : "flex-row   "}  flex w-full h-full items-center   justify-between `} >
        <div className=    {`${isArabic ? "items-end" : "items-start"}  flex h-full flex-col gap-1 w-full  justify-center`} >
       <h1 className="   h-[1rem] w-[30%] "><Skeleton  baseColor= {!theme ? "#3f3c3c" :"" }   height={'0.65rem'}   width={'100%'}  /></h1>
       <h1 className="   h-[1rem] w-[50%] "><Skeleton  height={'0.65rem'} baseColor= {!theme ? "#3f3c3c" :"" }  width={'100%'}  /></h1>
       </div>
       
        <Skeleton baseColor= {!theme ? "#3f3c3c" :"" } height={'1rem'}   width={'2rem'}  />
        
       </div>
       <Skeleton baseColor= {!theme ? "#3f3c3c" :"" } height={'1rem'}   width={'1rem'}  />
       {/* <img className=" h-[.4rem]  " src={openBtn} alt="" /> */}
        </div>
      )) 

    
  );
}

export default ReceiptsSkeleton;