// dataCleanup.js

import Cookies from 'js-cookie';
import Dexie from 'dexie';

export const clearDataAndLogout = async (setCard, setReceiptes, setUser, setFirstFetch) => {
  // Remove tokens
  Cookies.remove("access_token");

  // Remove highlighted items from localStorage
  localStorage.removeItem('highlightedItemIds');

  // Initialize Dexie database
  const Database = new Dexie('Database');
  
  // Define database schema
  Database.version(1).stores({
    receipts: 'created_at',
    cards: 'id',
    users: 'id',
  });

  try {
    // Clear each table in the database
    await Database.receipts.clear();
    await Database.users.clear();
    await Database.cards.clear();

    // Reset state variables
    setReceiptes([]);
    setCard([]);
    setUser(null);
    setFirstFetch(true);

    // Additional cleanup steps can be added here
  } catch (error) {
    // Handle errors if necessary
    console.error('Error during database cleanup:', error);
  }
};
