import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Signin from "./page/Signin";
import Signup from "./page/Signup";
import Language from "./page/Language";
import PersonalInfo from "./page/PersonalInfo";
import Currency from "./page/Currency";
import "./App.css";
import Massege from "./compenent/Notification";
import MenuBar from "./compenent/MenuBar";
import Account from "./page/Account";
import Cards from "./page/Cards";
import Offers from "./page/Offers";
import Statistics from "./page/Statistics";
import Receipts from "./page/Receipts";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Onboarding from "./compenent/Onboarding";
import { useContext } from "react";
import { Usercontext } from "./context/Usercontexte";
import CardDetails from "./compenent/CradComponent/CardDetails";
import AddCardManully from "./compenent/CradComponent/AddCardManully";
import TakeTour from "./compenent/TakeTour";
import { themecontext } from "./context/Themecontexte";
import LinkedAccount from "./page/LinkedAccount";
import InstallPage from "./compenent/InstallPage";
axios.defaults.baseURL = "https://django-cloudrun-5cc2zmgpqq-lz.a.run.app";

function App() {
  const { theme } = useContext(themecontext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [FirstVisit, setFirstVisit] = useState(false);
  const [onloading, setonloading] = useState(true);
  const getTour = localStorage.getItem("tour");
  const [tour, setTour] = useState(getTour ? JSON.parse(getTour) : false);

  const { i18n } = useTranslation();

  var isArabic = i18n.language.startsWith("ar");

  // App Loading
  setTimeout(() => {
    setonloading(false);
  }, 10);

  // cheking this the FirstVisit
  useEffect(() => {
    const getFirstVisit = localStorage.getItem("FirstVisit");
    setFirstVisit(getFirstVisit);
    if (currentIndex >= 4) {
      setFirstVisit(true);
      localStorage.setItem("FirstVisit", FirstVisit);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (currentIndex >= 4) {
      setTimeout(() => {
        setCurrentIndex(5);
      }, 2000);
    }
  }, [currentIndex]);

  return (
    <Router>
      <div
        className={` overflow-x-hidden   h-[100vh] overflow-auto duration-200 ease-out  ${
          isArabic ? " text-right" : " text-left"
        }    ${
          !theme ? "text-white bg-[#212121]" : " bg-white text-[#303030] "
        } `}
      >
        {!tour && currentIndex >= 4 && <TakeTour setTour={setTour} />}

        <Massege />

        <MenuBar />
        {!FirstVisit && currentIndex <= 4 && (
          <Onboarding
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        )}

        {/* <InstallPage /> */}

        {onloading && (
          <div className="  font-custom fixed bg-white w-full h-full top-0 right-0 z-[70] "></div>
        )}

        <Routes>
          <Route path="/Account" element={<Account />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Signin/:subpages" element={<Signin />} />
          <Route path="/PersonalInfo" element={<PersonalInfo />} />
          <Route path="/Language" element={<Language />} />
          <Route path="/Currency" element={<Currency />} />
          <Route path="/Cards" element={<Cards />} />
          <Route path="/Cards/:subpages" element={<Cards />} />
          <Route path="/Cards/CardDetails/:id" element={<CardDetails />} />
          <Route  path="/Cards/addcartmanully/:id" element={<AddCardManully />} />
          <Route path="/Offers" element={<Offers />} />
          <Route path="/linkedaccounts" element={<LinkedAccount />} />
          <Route path="/Statistics" element={<Statistics />} />
          <Route path="/Statistics/:subpages" element={<Statistics />} />
          <Route index element={<Receipts />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
