
// Adding the ids of the new items in the localStorage

export function addNewItemID(id , setHighlightedItems) {
    // Step 1: Check if localStorage is supported
    if (typeof localStorage === 'undefined') {
      console.error('localStorage is not supported in this browser');
      return;
    }
  
    // Step 2: Retrieve the existing array of IDs from localStorage
    let existingIDs = localStorage.getItem('highlightedItemIds');
  
    // If no existing data, create an empty array
    existingIDs = existingIDs ? JSON.parse(existingIDs) : [];
  
    // Step 3: Add the new ID to the array
    existingIDs.push(id);
  
    // Step 4: Save the updated array back to localStorage
    localStorage.setItem('highlightedItemIds', JSON.stringify(existingIDs));
  
    // Step 5: Update the React state
    setHighlightedItems(existingIDs);
  }
  