import { React, useEffect, useState } from "react";
 import digitalR from '../../assest/digitalR.svg';
 import physicalR from '../../assest/physicalR.svg'
import TrendChart from "./TrendChart";
import { useTranslation } from "react-i18next";

function ReceiptType({Period , filteredData}) {
  const [Physical , setPhysical] = useState()
  const [Digital , setDigital] = useState()
  const [totalDandPRec , settotalDandPRec] = useState({
    physical:'',
    digital:''
  })
  const {t , i18n } = useTranslation();
  var isArabic = i18n.language.startsWith('ar');
 


  useEffect(()=>{
 
const receiptsPerYearMonthDateAndType = {};

filteredData?.forEach(entry => {
  function getPeriod(timestamp) {
    const date = new Date(timestamp);
    if (Period === 'Yesterday' || Period === 'Today') {
      return { hour: date.getHours(), date: date.toISOString().split('T')[0], created_at: timestamp };
    } else if (Period === 'Last year' || Period === 'This year') {
      return { hour: date.getMonth(), date: date.toISOString().split('T')[0], created_at: timestamp };
    } else {
      return { hour: date.getDate(), date: date.toISOString().split('T')[0], created_at: timestamp };
    }
  }

  // Determine file type and store the date, hour, and count in the appropriate array
  const fileType = entry.file_type === 'png' ? 'physical' : (entry.file_type === 'pdf' ? 'digital' : 'unknown');
  const { hour, date, created_at } = getPeriod(entry.created_at);

  if (fileType !== 'unknown') {
    if (!receiptsPerYearMonthDateAndType[hour]) {
      receiptsPerYearMonthDateAndType[hour] = {
        digital: 0,
        physical: 0,
        created_at:[],
      };
    }

    // Increment the count for the corresponding file type
    receiptsPerYearMonthDateAndType[hour][fileType]++;
    receiptsPerYearMonthDateAndType[hour].created_at.push({created_at }); // Store the date and created_at in the result array
  }
});

let totalDigital = 0;
let totalPhysical = 0;
const treesSavedPerHour = {};

Object.values(receiptsPerYearMonthDateAndType).forEach(hourData => {
  totalDigital += hourData.digital;
  totalPhysical += hourData.physical;

  const treesSaved = Math.floor(hourData.digital / 3); // Assuming 1 tree is saved for every 3 digital receipts
  treesSavedPerHour[hourData.created_at[0]?.created_at] = treesSavedPerHour[hourData.created_at[0]?.created_at] || 0;
  treesSavedPerHour[hourData.created_at[0]?.created_at] += treesSaved;
});
 

 
    // Convert the values of receiptsPerYearMonthDateAndType into an array
    const result = Object.values(receiptsPerYearMonthDateAndType);

    settotalDandPRec({
      physical:totalPhysical,
      digital:totalDigital
    })
    
      setDigital({ data:result.map((i)=>(i.digital) ) , time:result.map((i)=>(
      i.created_at[0]?.created_at 
    ) ) } )

    
     setPhysical({ data:result.map((i)=>(i.physical) ) , time:result.map((i)=>(
      i.created_at[0]?.created_at 
    ) ) })

  
},[Period] )

return (
  <div className=" w-full flex flex-col pb-7  ">
      <TrendChart DATA2={Physical}  DATA1={Digital} state={true} Period={Period} colors={['#FFCC00' , '#06C671'] }  />
      <div className=  {`flex ${isArabic ? ' flex-row-reverse' : ' flex-row' }  w-full justify-between   pt-2    text-left`} >
        <span
          className={` w-[80%]   py-4 flex ${isArabic ? ' flex-row-reverse' : ' flex-row' } gap-2  text-[.8rem]  items-center justify-start  font-[400] `}
        >
          <span className={`w-3 h-3 rounded-full  bg-main  `}></span>{" "}
          <h1 className="  text-[.96rem]  "> {t('Stats.DR')} </h1>
        </span>

        <span
          className={` w-full  justify-end py-4    flex  ${isArabic ? ' flex-row-reverse' : ' flex-row' } gap-2 text-[.8rem]  text-center  items-center  font-[400] `}
        >
          <span className={`w-3 h-3 rounded-full bg-[#FFCC00] `}></span>{" "}
          <h1 className="  text-[.96rem]  ">{t('Stats.PR')}</h1>
        </span>
      </div>
      
      <div className=" w-full flex flex-row justify-between pt-[1.5rem] gap-9    text-center  ">
        <div
          
          className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[1.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] "
        ><img
            src={physicalR}
            className=" bg-[#E5FFF4] w-[3rem] p-[.2rem] rounded-full h-[3rem]   "
            alt=""
          />
          
          <h1 className=" text-[.9rem] leading-5 font-medium ">
           {t('Stats.totalD')}
          </h1>
          <p className=" text-[1.1rem] font-[500] ">{totalDandPRec?.digital} </p>
        </div>
        <div
     
          className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[1.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.8rem] "
        >
          <img
            src={digitalR}
            className=" bg-[#FFF3D0] w-[3rem] p-[.4rem] rounded-full h-[3rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium ">
          {t('Stats.totalP')}
          </h1>
          <p className=" text-[1.1rem] font-[500] ">{totalDandPRec?.physical} </p>
        </div>
      </div>
    </div>
  );
}

export default ReceiptType;
