import { React, useContext, useState } from "react";
import LoadingBtn from "./LoadingBtn";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { RestPassVal } from "../Validation/RestPassVal";
import { Link } from "react-router-dom";
import Masseege from "./masseege";
import { useNavigate } from "react-router-dom";
import { Usercontext } from "../context/Usercontexte";
import { themecontext } from "../context/Themecontexte";

function RestPass({msg , setMsg}) {
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useContext(themecontext);
 
 const Navto = useNavigate();

  const { values, handleBlur, errors, touched, handleChange, handleSubmit } =
  useFormik({
    initialValues: {
      email: "",
      
    },
    validationSchema: RestPassVal,
    onSubmit,
  });
  const {t , i18n} = useTranslation()
var isArabic = i18n.language.startsWith('ar');


  function onSubmit() {
    setIsLoading(true);
    axios
      .post(
        "/api/clients/reset_password",
        {
          email: values.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        Navto('/Signin/codeverification')
        localStorage.setItem('email',values.email)
        setMsg({
            text: t('Msg.VcSent') ,
            color: "bg-[#4fd837] text-[0.9rem] ",
          });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        if (error.response.data) {
              setMsg({
          text: error.response.data.message,
          color: "bg-[#FF1203F5] text-[0.9rem] ",
        });
        }
      
      });
  }

 

  return (
    <div className= {`pt-[6rem] pb-[3.4rem]   text-center fixed top-0 px-[1.2rem] right-0 z-30 w-full h-full flex flex-col justify-between  ${!theme ? ' bg-[#212121] ' :' bg-white' } `} >
    <div className=" h-[57%] w-full flex flex-col justify-between ">

   
      <div className=" w-full flex flex-col items-center gap-[1rem]  ">
        <h1 className=" text-[1.6rem] font-[700] ">{t('Signin.Repass')} </h1>
        <p className=" text-center text-sec text-[.92rem] ">
         {t('Signin.EmailRes')}<br /> 
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col   ">
          <h1 className= {`pb-2  ${isArabic ? ' text-right' :' text-left' }     text-[.9rem] font-medium `} >
          {t('Signup.Email')}
          </h1>

          <input
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            placeholder=  {t('Signup.Email')}
            className={`  ${isArabic ? ' text-right'  :' text-left'} ${
              errors.email && touched.email
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            }   text-sec w-full h-[3.6rem]  bg-transparent px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="Email"
            name=""
            id="email"
          />
          {errors.email && touched.email && (
            <p className=" text-left pt-1 px-1 text-xs text-red-400"> {errors.email} </p>
          )}
        </div>
        <div className=" w-full">
          <button
            type="submit"
            className=" h-[3.6rem] relative mt-[1.58rem]  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
          >
            {t('Signin.SendCode')}
            <LoadingBtn color={'#fff'} bg={'bg-main'} isLoading={isLoading} small={true} />
          </button>
        </div>
      </form>

      </div>
      <span className=" text-[.95rem] text-sec ">
        {" "}
        {t('Signin.RemePass')}
        <Link to={'/Signin'} className=" text-main   font-medium ">   {t('topbar.Signin')}</Link>{" "}
      </span>
      <Masseege msg={msg} setMsg={setMsg} />

    </div>
  );
}

export default RestPass;
