import React from 'react'
import { useTranslation } from "react-i18next";

function NoCards() {
  
  const {t , i18n} = useTranslation()
 var isArabic = i18n.language.startsWith('ar');
  return (
    <div className='  w-full h-[87vh] justify-center items-center gap-2 flex flex-col '>
        <h1 className=' text-[1.5rem] font-medium '> {t('card.AddfirstC')}</h1>
        <p className=' text-sec'> {t('card.Adding')} </p>
    </div>

  )
}

export default NoCards