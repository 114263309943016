
import { React, useState ,  useContext } from "react";
import tour from "../assest/tour.svg";
 import { themecontext } from "../context/Themecontexte";


function AskForTour({ setState , State , setStep ,setTour }) {
  const { theme } = useContext(themecontext);
  
 function Skip (){
  localStorage.setItem('tour' , true )
  setState(!State);
   setTour(true)
 }
 
 
  return (
    <div
      className={` duration-300  ${
        State ? "top-[50%]" : " top-[-100%] -z-10 "
      }  flex  z-[120] fixed h-full w-full relateve `}
    >
      <div
        onClick={Skip}
        className={`  flex  fixed h-full w-full absulet ${
          State ? " opacity-50 top-0  " : "opacity-0  top-[-100%] -z-10  "
        }  right-0 bg-black `}
      ></div>

      <div
        className={` text-center ${
          State ? "  z-30  opacity-100  top-[100%] " : " opacity-30   hidden -z-10    "
        }   duration-300     transform      flex   translate-x-[-50%]   translate-y-[-50%]  top-[50%]  h-[20rem]    left-[50%]   fixed  w-[80%]      flex-col items-center justify-between  pt-[1.6rem] pb-[3.5rem] px-[.74rem]    rounded-[.31rem]  ${theme ? ' bg-white' : ' bg-[#212121]'  }   `}
      >
        <div className=" absolute top-0 w-full    flex items-center    right-0     ">
          <span
            onClick={Skip}
            className=" absolute top-4 right-4 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 18 17"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.40101 0.72223C7.49487 0.379293 10.6172 0.379293 13.711 0.72223C15.424 0.91423 16.806 2.26323 17.007 3.98223C17.3739 7.11987 17.3739 10.2896 17.007 13.4272C16.9053 14.2625 16.5244 15.039 15.9262 15.6307C15.3279 16.2224 14.5474 16.5947 13.711 16.6872C10.6172 17.0312 7.49481 17.0312 4.40101 16.6872C3.56466 16.5947 2.78409 16.2224 2.18583 15.6307C1.58758 15.039 1.20672 14.2625 1.10501 13.4272C0.738108 10.2899 0.738108 7.12054 1.10501 3.98323C1.20668 3.1481 1.58739 2.37178 2.18544 1.78007C2.7835 1.18837 3.56384 0.815974 4.40001 0.72323L4.40101 0.72223ZM5.52601 5.17523C5.66664 5.03478 5.85726 4.95589 6.05601 4.95589C6.25476 4.95589 6.44539 5.03478 6.58601 5.17523L9.05601 7.64523L11.526 5.17523C11.5947 5.10154 11.6775 5.04244 11.7695 5.00145C11.8615 4.96046 11.9608 4.93841 12.0615 4.93664C12.1622 4.93486 12.2622 4.95339 12.3556 4.99111C12.449 5.02883 12.5338 5.08497 12.605 5.15619C12.6763 5.22741 12.7324 5.31224 12.7701 5.40563C12.8079 5.49902 12.8264 5.59905 12.8246 5.69975C12.8228 5.80045 12.8008 5.89977 12.7598 5.99177C12.7188 6.08377 12.6597 6.16657 12.586 6.23523L10.116 8.70523L12.586 11.1752C12.7185 11.3174 12.7906 11.5055 12.7872 11.6998C12.7838 11.8941 12.705 12.0794 12.5676 12.2169C12.4302 12.3543 12.2448 12.433 12.0505 12.4364C11.8562 12.4398 11.6682 12.3677 11.526 12.2352L9.05601 9.76523L6.58601 12.2352C6.51735 12.3089 6.43455 12.368 6.34255 12.409C6.25055 12.45 6.15124 12.472 6.05053 12.4738C5.94983 12.4756 5.8498 12.4571 5.75641 12.4194C5.66303 12.3816 5.57819 12.3255 5.50697 12.2543C5.43575 12.183 5.37961 12.0982 5.34189 12.0048C5.30417 11.9114 5.28564 11.8114 5.28742 11.7107C5.2892 11.61 5.31124 11.5107 5.35223 11.4187C5.39322 11.3267 5.45232 11.2439 5.52601 11.1752L7.99601 8.70523L5.52601 6.23523C5.38556 6.0946 5.30667 5.90398 5.30667 5.70523C5.30667 5.50648 5.38556 5.31586 5.52601 5.17523Z"
                fill="#E3E3E7"
              />
            </svg>
          </span>
        </div>

        <div
          className=" w-[4rem] h-[4rem]   bg-no-repeat bg-cover  "
          style={{ backgroundImage: `url(${tour})` }}
        ></div>
<div className=" flex flex-col gap-1">
<h1 className=  {`  ${theme  ? 'text-[#25215e]' : ' text-white' } text-[1.1rem] font-semibold  text-center `}   >
        Let’s take a quick tour together
        </h1>
        <p className=" text-sm text-sec" >First time using the app let’s take a <br /> tour together</p>
</div>
        <button onClick={()=>{setStep(0); setState(!State) }} className=" w-full bg-main text-[1.1rem] font-semibold  text-white h-[3.5rem] rounded-[.31rem] ">
        Take Tour
        </button>
        <p  
         onClick={Skip}
         className=" text-[#C4C4C4]  absolute bottom-4 ">Skip</p>
      </div>
    </div>
  );
}

export default AskForTour;
