import { React, useState, useContext, useEffect } from "react";
import TopBar from "../compenent/TopBar";
import { PersonlSchema } from "../Validation/PersonlSchema";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "js-cookie";
import { Usercontext } from "../context/Usercontexte";
import "react-phone-number-input/style.css";
import { MuiTelInput } from "mui-tel-input";
import Masseege from "../compenent/masseege";
import LoadingBtn from "../compenent/LoadingBtn";
import { useTranslation } from "react-i18next";
import { themecontext } from "../context/Themecontexte";

 
function PersonalInfo() {
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState({ text: "", color: "" });
  const [phone, setPhone] = useState("");
  const [isPhoneRequired, setIsPhoneRequired] = useState("");
  const {theme} = useContext(themecontext);
  const { User,setUser, setUpdate, update  } = useContext(Usercontext);
 
  const {t,i18n} = useTranslation()
  var isArabic = i18n.language.startsWith('ar');

  const handleChangePhone = (newValue) => {
    setPhone(newValue);
    if (newValue.length< 8) {
      setIsPhoneRequired('min');
    } else {
      setIsPhoneRequired(null); // Reset the required flag when the phone is valid
    }
  };


  // updating the user info
  function onSubmit() {
   if(isPhoneRequired == 'min'){
    return
   }
     const fcmtoken =   localStorage.getItem('FCMTOKEN')
    setIsLoading(true);
    const token = Cookies.get("access_token");
    axios
      .put(
        "/api/clients/me",
        {
          email: values.email,
          last_name: values.lastname,
          first_name: values.firstname,
          phone: phone,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setUpdate(!update);
        setUser(response?.data)
        setIsLoading(false);
        setEdit(false);
        setMsg({
          text: "Profile was updated successfully",
          color: "  bg-[#4fd837]",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        setMsg({
          text: error.response.data.email
            ? t('Msg.EmailAlreadyUsed')
            : t('Msg.ProfileUpdateError'),
          color: "bg-[#FF1203F5]",
        });
      });
  }


// geting the user info form the localDB and Dislpay it 
  useEffect(() => {
    setPhone(User?.phone);
    setValues({
      firstname: User?.first_name,
      lastname: User?.last_name,
      email: User?.email,
    });
  }, [User, edit]);



  const {
    setValues,
    values,
    handleBlur,
    errors,
    touched,
    handleChange,

    handleSubmit,
  } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      
    },
    validationSchema: PersonlSchema,
    onSubmit,
  });

  {
    errors.firstname && touched.firstname && (
      <p className=" pt-1 px-1 text-xs text-red-400"> {errors.firstname} </p>
    );
  }

  return (
    <div className=" px-[1.2rem] pt-[1.8rem] flex flex-col pb-20 ">
      <div className=" relative">
        {!edit && (
          <span
            onClick={() => setEdit(!edit)}
            className={` z-20 absolute    cursor-pointer  top-0 right-0 p-[.4rem] bg-[#F3F3F3] rounded-full `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M8.5269 3.54721L6.434 1.47893L7.12343 0.789505C7.3122 0.600734 7.54414 0.506348 7.81925 0.506348C8.09437 0.506348 8.32615 0.600734 8.51459 0.789505L9.20402 1.47893C9.39279 1.6677 9.49128 1.89554 9.49949 2.16245C9.50769 2.42936 9.41741 2.65703 9.22864 2.84548L8.5269 3.54721ZM7.81285 4.27357L2.5929 9.49352H0.5V7.40062L5.71995 2.18067L7.81285 4.27357Z"
                fill="#5B5B5B"
              />
            </svg>
          </span>
        )}
        <TopBar text={t('topbar.Ptoptitle')} />
      </div>

      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col  pt-10 ">
          <h1 className=" font-medium  pb-2   pt-[1.5rem] text-[.9rem] ">
          {t('Signup.Fname')}
          </h1>
          <input
            disabled={!edit}
            placeholder={t('Signup.Fname')}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstname}
            className={`  ${isArabic?'text-right':'text-left'} ${
              errors.firstname && touched.firstname
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[3.2rem] ${
              !edit
                ? "  border-[0px]"
                : "border-solid border-[1px] bg-transparent text-gray-800"
            }   px-[1rem]   text-sm   rounded-[0.32rem]`}
            type="text"
            name=""
            id="firstname"
          />
          {errors.firstname && touched.firstname && (
            <p className="   pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.firstname}{" "}
            </p>
          )}
        </div>
        <div className=" flex flex-col  ">
          <h1 className="pb-2  pt-[1.5rem] text-[.9rem] font-medium ">
             {t('Signup.Lname')}
          </h1>
          <input
            disabled={!edit}
            placeholder= {t('Signup.Lname')}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastname}
            className={`  ${isArabic?'text-right':'text-left'} ${
              errors.lastname && touched.lastname
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[3.2rem]   px-[1rem] ${
              !edit
                ? "  border-[0px]"
                : "border-solid border-[1px] bg-transparent text-gray-800"
            }  text-sm   rounded-[0.32rem]`}
            type="text"
            id="lastname"
          />
          {errors.lastname && touched.lastname && (
            <p className=" pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.lastname}{" "}
            </p>
          )}
        </div>

        <div className=" flex flex-col   ">
          <h1 className="pb-2   pt-[1.5rem] text-[.9rem] font-medium  ">
            {t('Signup.Email')}
          </h1>
          <input
            disabled={!edit}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            placeholder="example@gmail.com"
            className={`  ${isArabic?'text-right':'text-left'} ${
              errors.email && touched.email
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[3.2rem]   px-[1rem] ${
              !edit
                ? "  border-[0px]"
                : "border-solid border-[1px] bg-transparent text-gray-800"
            }   text-sm    rounded-[0.32rem]`}
            type="Email"
            name=""
            id="email"
          />
          {errors.email && touched.email && (
            <p className=" pt-1 px-1 text-xs text-red-400"> {errors.email} </p>
          )}
        </div>
       
        <div className=" flex flex-col gap-2">
          <h1 className="pb-2   pt-[1.5rem] text-[.9rem] font-medium ">
          {t('Signup.Phone')}  
          </h1>
          <MuiTelInput
            className={`  ${isArabic?'text-right':'text-left'} ${
              !edit ?  ` ${!theme ? 'bg-[#5f5f5f] text-white ' : ' text-gray-800 bg-[#fafafa]'   }   border-none border-[0px] rounded-md` : "rounded-md  bg-transparent"
            }   `}
            id="phonenumber"
            disabled={!edit}
            value={phone}
            onClick={()=>setIsPhoneRequired('') }
            name="phonenumber"
            onChange={handleChangePhone}
          />
            {isPhoneRequired == 'min' &&  (
            <p className=" pt-1 px-1 text-xs text-red-400"> phonenumber is a required field (min 8 char) </p>
          )}
        </div>

        {edit && (
          <div className=" w-full pt-10 flex flex-row gap-2 ">
            <button
              onClick={(e) => {
                setEdit(false);
                e.preventDefault();
              }}
              className=" h-[3.4rem]  text-[1.1rem] bg-slate-100 text-gray-700   font-medium   w-full  rounded-[0.32rem] "
            >
              {t('card.Cancel')}
            </button>
            <button
              type="submit"
              className=" h-[3.4rem] relative  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
            >
              Save
              <LoadingBtn isLoading={isLoading}  bg={'bg-main'} />
            </button>
          </div>
        )}
      </form>
      <Masseege msg={msg} setMsg={setMsg} />
    </div>
  );
}

export default PersonalInfo;
