import { React, useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
import TrendChart from "./TrendChart";
import star from "../../assest/star.svg";

function Eimpact({filteredData,Period , PeriodArray}) {
  const [Digital , setDigital] = useState()
  const [Empact , setEmpact] = useState()
  const [EStatistic , setEStatistic] = useState({
    digital:'',
    NmberTree:''
  })
    const {t , i18n } = useTranslation();
   var isArabic = i18n.language.startsWith('ar');

   
 
  
useEffect(()=>{ 

  const receiptsPerYearMonthDateAndType = {};

  filteredData?.forEach(entry => {
    function getPeriod(timestamp) {
      const date = new Date(timestamp);
      if (Period === 'Yesterday' || Period === 'Today') {
        return { hour: date.getHours(), date: date.toISOString().split('T')[0], created_at: timestamp };
      } else if (Period === 'Last year' || Period === 'This year') {
        return { hour: date.getMonth(), date: date.toISOString().split('T')[0], created_at: timestamp };
      } else {
        return { hour: date.getDate(), date: date.toISOString().split('T')[0], created_at: timestamp };
      }
    }
  
    // Determine file type and store the date, hour, and count in the appropriate array
    const fileType = entry.file_type === 'png' ? 'physical' : (entry.file_type === 'pdf' ? 'digital' : 'unknown');
    const { hour, date, created_at } = getPeriod(entry.created_at);
  
    if (fileType !== 'unknown') {
      if (!receiptsPerYearMonthDateAndType[hour]) {
        receiptsPerYearMonthDateAndType[hour] = {
          digital: 0,
          physical: 0,
          created_at:[],
        };
      }
  
      // Increment the count for the corresponding file type
      receiptsPerYearMonthDateAndType[hour][fileType]++;
      receiptsPerYearMonthDateAndType[hour].created_at.push({created_at }); // Store the date and created_at in the result array
    }
  });
  let totalDigital = 0;

  let treesSavedPerHour=[]
  Object.values(receiptsPerYearMonthDateAndType).forEach(hourData => {
    totalDigital += hourData.digital;
    const treesSaved = Math.floor(hourData.digital / 3); // Assuming 1 tree is saved for every 3 digital receipts
    const hourTimestamp = hourData.created_at[0]?.created_at;
       
    treesSavedPerHour.push({ time: hourTimestamp, treesSaved: treesSaved });
  });
 
  const  totalTrees = totalDigital / 3;

  setEStatistic({
    digital:totalDigital,
NmberTree:totalTrees.toFixed(0)
  })
  setEmpact({
    time:treesSavedPerHour.map((i)=>(i.time) ),
    data:treesSavedPerHour.map((i)=>(i.treesSaved) )
  })
  // Convert the values of receiptsPerYearMonthDateAndType into an array
  const result = Object.values(receiptsPerYearMonthDateAndType);
  setDigital({ data:result.map((i)=>(i.digital) ) , time:result.map((i)=>(
    i.created_at[0]?.created_at 
  ) ) } )
 
} ,[Period] )



  return (
    <div className=" w-full flex flex-col pb-7  ">
      <TrendChart state={true} DATA1={Digital}  DATA2={Empact} Period={Period}  colors={['#0625C6' , "#06C671"]} />
      <div className= {` flex flex-row w-full justify-between   pt-2    ${isArabic ? ' flex-row-reverse' : ' flex-row' }`}  >
        <span
          className={` w-[80%] ${isArabic ? ' flex-row-reverse' : ' flex-row' }  py-4 flex flex-row gap-2  text-[.8rem]  items-center justify-start  font-[400] `}
        >
          <span className={`w-3 h-3  rounded-full bg-[#0625C6]  `}></span>{" "}
          <h1 className="  text-[.96rem]  ">{t('Stats.DR')} </h1>
        </span>

        <span
          className={` w-full ${isArabic ? ' flex-row-reverse' : ' flex-row' } justify-end py-4    flex flex-row gap-2 text-[.8rem]  text-center  items-center  font-[400] `}
        >
          <span className={`w-3 h-3 rounded-full bg-main  `}></span>{" "}
          <h1 className="  text-[.96rem]  ">{t('Stats.EImpact')}</h1>
        </span>
      </div>
      <div className="   flex flex-row  justify-center font-[600]  pt-[2.3rem] w-full items-center  text-center  ">
    { EStatistic?.digital > 0 &&  <div className=" w-[65%] relative">
          <img className=" absolute top-1 w-[33px] -right-6 " src={star} alt="" />
          <img className=" absolute top-1  w-[33px]  -left-6" src={star} alt="" />

          <h1 className="text-[1.7rem] ">

            <span className=" text-main"> {t('Stats.Greatjob')} </span> <br />    {t('Stats.digitalUsed' , {num:EStatistic?.digital} )}   <span className=" text-main"> {t('Stats.saving',{number:EStatistic?.NmberTree} )} { PeriodArray.find((i)=> i.value == Period ).text } </span>
          </h1>
        </div>}
      </div>
    </div>
  );
}

export default Eimpact;
