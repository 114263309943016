import {React , useState } from 'react'
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
function Story({stories ,setStoryOpen }) {
const [currentIndex , setCurrentIndex ] = useState(0)
const {t , i18n} = useTranslation()
var isArabic = i18n.language.startsWith('ar');  
function nextStory() {
    if (currentIndex < stories?.story?.length - 1) {
        setCurrentIndex(currentIndex + 1);
    } else {
        setStoryOpen(false)
      
    }
  }

  function prevStory() {
    if (currentIndex > 0) {
        setCurrentIndex(currentIndex-1)
    } else {
       
    }
  }

 
  return (
    <div className=' items-center w-full h-full fixed flex flex-col  pt-1  top-0 right-0 bg-black z-40'>
<div className='    w-[97%] flex flex-row gap-1'>
{stories?.story?.map((i , index)=>(
    <span key={index} className= {` ${currentIndex >= index ? ' bg-white ' :'' } h-[.3rem] w-full bg-gray-500`} ></span>
)  ) }
</div>

<div className=  {` ${isArabic ? ' flex-row-reverse' :'flex-row' }  w-full items-center flex  justify-between px-5  py-4`} >
<span  className= {`items-center gap-3 flex text-white ${isArabic ? ' flex-row-reverse' :'flex-row' }`} >
<img className=' h-[3.4rem] w-[3.4rem] rounded-full object-cover  border-solid border-[1px]  ' src={stories.img} alt="" />
<span className=' flex flex-col '>
    <h1>{stories.text} </h1>
<p className=' text-sm'>valid for 5 days</p>
</span>

</span>
<span onClick={()=> setStoryOpen(false) }>

 <CloseIcon className='text-white'/>
</span>
</div>

<span className='   w-full relative '>
    
<img className=' w-full h-[60vh]   ' src={stories?.story[currentIndex]?.img} alt="" />
    <div className=' absolute top-0 right-0 w-full h-full flex flex-row'>
    <span onClick={()=>{prevStory()}} className='active:bg-white bg-opacity-25   opacity-20 h-full w-full '>
</span>
<span onClick={()=>{nextStory() } } className=' active:bg-white bg-opacity-25 opacity-20 h-full w-full '>
</span>
    </div>
</span>
<Link to={stories?.link} className=' bg-main px-4 py-3 text-white font-semibold rounded-md my-8'>
{t('card.Checkit')}
</Link>
</div>
  )
}

export default Story