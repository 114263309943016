import React from 'react'
import TopBar from '../compenent/TopBar'
import { useTranslation } from "react-i18next";
import { useContext } from 'react';
  import { Usercontext } from '../context/Usercontexte';
function Currency() {
  
  const {t , i18n} = useTranslation()
  var isArabic = i18n.language.startsWith('ar');
  
    const {setTheme } = useContext(Usercontext)

  return (
    <div className='  pt-[1.2rem] px-[1.3rem] h-[100vh] '>
       <TopBar text={t('topbar.Ctoptitle')}/>
       <div className=' flex flex-col pt-[1.4rem] '>
       <span className=" relative w-full ">
       <p className= {` text-[#9C9C9C]  flex flex-row gap-[.35rem] items-center  absolute  top-[1.5rem]   ${isArabic ?' right-3' :' left-3' } `}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M9.64153 8.21094H9.06072L8.85486 8.01243C9.31433 7.47865 9.65013 6.84991 9.83823 6.1712C10.0263 5.49249 10.0621 4.78059 9.94296 4.08645C9.59742 2.04259 7.89175 0.410438 5.83318 0.160469C5.10946 0.0689108 4.37438 0.144126 3.6842 0.38036C2.99402 0.616593 2.36703 1.00758 1.8512 1.52341C1.33537 2.03924 0.944382 2.66623 0.708149 3.35641C0.471916 4.04659 0.3967 4.78167 0.488258 5.50539C0.738227 7.56396 2.37038 9.26963 4.41424 9.61517C5.10838 9.73431 5.82028 9.69855 6.49899 9.51044C7.1777 9.32234 7.80644 8.98654 8.34022 8.52707L8.53873 8.73293V9.31374L11.6633 12.4384C11.9648 12.7398 12.4574 12.7398 12.7588 12.4384C13.0602 12.1369 13.0602 11.6443 12.7588 11.3429L9.64153 8.21094ZM5.23031 8.21094C3.39966 8.21094 1.9219 6.73318 1.9219 4.90252C1.9219 3.07187 3.39966 1.59411 5.23031 1.59411C7.06097 1.59411 8.53873 3.07187 8.53873 4.90252C8.53873 6.73318 7.06097 8.21094 5.23031 8.21094Z"
              fill="#C1C1C1"
            />
          </svg>{" "}
        </p>
        <input 
              type="text"
              className={` text-[.75rem] px-8  ${isArabic ?'  text-right ' :'  text-left'}    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
              placeholder={t('profile.searchPlaceh') + ` `+ t('topbar.Ctoptitle')}

            />
        
      </span>

<div className=' flex flex-col gap-2  pt-[2rem] '>
<div className= {`flex   ${isArabic ?' flex-row-reverse':' flex-row'}  w-full py-2 justify-between items-center`}>
 
<span className=  {`flex ${isArabic ?' flex-row-reverse':' flex-row'} items-center gap-2`} >
<img  className=' h-[3.4rem] w-[3.4rem] ' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+klEQVR4nO2VrQoCQRSFx5eybhAWtmkSxGLWJzD4ACbrWn0FFxQVDGKzCQo2g2lAFydZruyCFg3uMr/O+eDA3HYP9565jAEAAADAMERUCRoJyZaoVZWJ/aWBqDWn0Xj/bqBo3etvcxkxEDZndDjdKGMw3FHRut1dU3p/5MremQF+TZWJfZtAPDnSanOhemdJZerp4pzrNQHtBgLJMmIgkpgD7SEOJefAyDcaS8yB83eA685AJPkOaDUQ4g4kH3fA+QwIGKj+PgGVgdMSYm5BU9xrA0Jh4LT8QsKCpoTXBrgFe829DjF33YCwYK+F1yEWLhsAAAAAAGCleAKWmufwwWk9CQAAAABJRU5ErkJggg=="/>

<span>
  <h1 className='  text-[.95rem] font-semibold'>USD</h1>
  <p className=' text-sm text-sec '>United states </p>
</span>
</span>
<h1 className=' font-semibold'>$</h1>
 
</div>



</div>

       </div>
    </div>
  )
}

export default Currency