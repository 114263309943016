import React from 'react'

function LoadingBtn({isLoading , small , bg , color }) {
  return (
    <span
            className={` ${
               isLoading ? " opacity-100 " : " opacity-0"
            } bg-greedian   absolute top-0 right-0  ${bg} rounded-lg duration-200 h-full w-full flex items-center justify-center   `}
          >
            <span className= {` h-full w-full  ${small  ? ' scale-[0.2]'  :  'scale-[0.4]'  }    flex items-center justify-center `}>
              <svg
                version="1.1"
                id="L9"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml
                space="preserve"
              >
                
                <path
                  fill={color}
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50  50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </span>
          </span>
  )
}

export default LoadingBtn