import Dexie from "dexie";

const Database = new Dexie('Database');
Database.version(1).stores({
  receipts: 'created_at',
});

export const checkAndAddReceiptsToDB = async (receiptData , setMassage  , addNewItemID ,setReceiptes,setHighlightedItems ) => {
  try {
    const existingReceipt = await Database.receipts.get(receiptData.id);

    if (!existingReceipt) {
      // Set the "created_at" key path when adding a new record
      receiptData.created_at = new Date().toISOString();
      
      await Database.transaction('rw', Database.receipts, async () => {
        await Database.receipts.put(receiptData);
      });
      addNewItemID(receiptData?.id  , setHighlightedItems);
      setReceiptes((prevReceiptes) => {
        if (prevReceiptes === null) {
          return [receiptData];
        } else {
          return [receiptData, ...prevReceiptes];
        }
      });
      setMassage('new')
    } else {
      // Set the "created_at" key path when updating an existing record
      receiptData.created_at = existingReceipt.created_at;

      await Database.receipts.update(receiptData.id, receiptData);
    }
  } catch (error) {
    console.error('Error handling receipts in the database:', error);
  }
};

