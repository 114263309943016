import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

const ScannDirctly = ({ code, setCode }) => {
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [isScannerRunning, setIsScannerRunning] = useState(false);
  let qrCodeScanner;

  const onScanSuccess = (decodedText) => {
    console.log('Code decoded: ', decodedText);
    setCode(decodedText);
    // Handle the decoded text as needed
  };

  useEffect(() => {
    const initQrCodeScanner = async () => {
      try {
        qrCodeScanner = new Html5Qrcode('qrcode-reader');
        const cameras = await Html5Qrcode.getCameras();
        setDevices(cameras);

        if (cameras.length > 0) {
          setSelectedDeviceId(cameras[0].id);
        }
      } catch (error) {
        console.error('Error initializing QR code scanner: ', error);
      }
    };

    initQrCodeScanner();

    // Cleanup when component unmounts
    return () => {
      if (qrCodeScanner) {
        try {
          qrCodeScanner.stop().then(() => {
            console.log('Code scanner stopped.');
            setIsScannerRunning(false);
          });
        } catch (error) {
          console.error('Error stopping QR code scanner: ', error);
        } finally {
          qrCodeScanner.clear();
        }
      }
    };
  }, []);

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  const handleStartStopClick = async () => {
    if (isScannerRunning) {
      try {
        await qrCodeScanner.stop();
        setIsScannerRunning(false);
      } catch (error) {
        console.error('Error stopping QR code scanner: ', error);
      } finally {
        qrCodeScanner.clear();
      }
    } else {
      if (selectedDeviceId) {
        try {
          qrCodeScanner = new Html5Qrcode('qrcode-reader');
          await qrCodeScanner.start(selectedDeviceId, { fps: 10, qrbox: 250 }, onScanSuccess);
          setIsScannerRunning(true);
        } catch (error) {
          console.error('Error starting QR code scanner: ', error);
        }
      }
    }
  };

  return (
    <div>
      <div className='qrcode-reader' id="qrcode-reader"></div>
      <div>
        <label htmlFor="cameraSelector">Select Camera:</label>
        <select id="cameraSelector" onChange={handleDeviceChange} value={selectedDeviceId}>
          {devices.map((device) => (
            <option key={device.id} value={device.id}>
              {device.label}
            </option>
          ))}
        </select>
      </div>
     {!isScannerRunning && <button className=' text-white font-medium  bg-main  p-3 px-5 mt-3 rounded-md' onClick={handleStartStopClick}>
        {isScannerRunning ? 'Stop Scanning' : 'Start Scanning'}
      </button>}
    </div>
  );
};

export default ScannDirctly;
