import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion";

function Card({img , id ,Sname}) {
  
    const item = {
        hidden: { y: -10, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
        },
      };
    
  return (
    <motion.div
    className=' w-full
     h-[6.4rem] '
    variants={item}>
    <Link className=" w-full relative h-[6.4rem]" to={`/Cards/CardDetails/${id}`}>  
    {!img ?  <span className="  flex justify-center items-center text-[2.4rem] font-bold text-white   w-full h-full   rounded-[.30rem]  bg-main ">
    {Sname?.charAt(0)}
    </span> 
     :  
    <img className="  w-full rounded-md  h-[6.4rem] object-cover " src={img} alt="" />}
    </Link>
    </motion.div>
  )
}

export default Card