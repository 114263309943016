import { React, useState, useContext, useEffect } from "react";
import ProductDetails from "../compenent/ProductDetails";
import Filter from "../compenent/Filter";
import { motion } from "framer-motion";
import NoReceipts from "../compenent/ReceiptsCompo/NoReceipts";
import DisplayReceipt from "../compenent/DisplayReceipt";
import Navbar from "../compenent/Navbar";
import { Usercontext } from "../context/Usercontexte";
import PDFViewer from "../compenent/ReceiptsCompo/PDFveiw";
import ReceiptsSkeleton from "../compenent/LoadingSkeleton/ReceiptsSkeleton";
import { useTranslation } from "react-i18next";
import Compressor from 'compressorjs';
import { themecontext } from "../context/Themecontexte";
import FakeReceipt from "../compenent/FakeReceipt";
import Cookies from "js-cookie";
import { GetReceiptFromEmail , setIdAndNavigateToImportPage } from "../API/importEmails";
import Social from "../compenent/Account/Socials";
import ConfirmReceipts from "../compenent/ReceiptsCompo/ConfirmReceipts";

function Receipts() {
  const [ImgOpen, setImgOpen] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [FilterOpen, setFilterOpen] = useState(false);
    const [intigration, setIntigration] = useState(null);
    const [loadingReceipt , setloadingReceipt] = useState(false)
    const [ConfirmIsOpen , setConfirmIsOpen] = useState(false)
  const { t, i18n } = useTranslation();
  const { User, Receiptes } = useContext(Usercontext);
  const { theme } = useContext(themecontext);
  const [FiltredData, setFiltredData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  var isArabic = i18n.language.startsWith("ar");
  const [EmailReceipts , setEmailReceipts] = useState(null)
  const [ImageURL , setImageURL] = useState(null)
  const [compressedImageURL , setcompressedImageURL] = useState(null)
  const { isLoading } = useContext(Usercontext);

  useEffect(() => {
    if (searchTerm == "") {
      setFiltredData(Receiptes);
    }
  }, [searchTerm]);



  useEffect(()=>{
  const location = window.location
  const searchParams = new URLSearchParams(location.search);
  const integration = searchParams.get('integration');
  setIntigration(integration)
  const externalAccountId = setIdAndNavigateToImportPage(location)
 if(externalAccountId){
   const access_token = Cookies.get('access_token')
   setTimeout(() => {
     setloadingReceipt(true)
   }, 1000);
if(integration == 'gmail' ){
  async function getReceipt(){
   const message = await  GetReceiptFromEmail(access_token , 'gmail', externalAccountId )
   setEmailReceipts({
    data:message,
    source:'gmail'
   })
   setTimeout(() => {
    setConfirmIsOpen(true)
   }, 5000);
  }
  getReceipt()
}else{
  async function getReceipt(){
    const message = await    GetReceiptFromEmail(access_token , 'outlook', externalAccountId )
    setEmailReceipts({
     data:message,
     source:'outlook'
    })
    setTimeout(() => {
      setConfirmIsOpen(true)
     }, 5000);
   }
   getReceipt()
}
 }
},[] )



  const handleSearch = () => {
    const results = Receiptes?.filter((receipt) => {
      // If search term is empty, return all Receiptes
      if (!searchTerm) {
        return true;
      }

      // Search in products' names
      const productsMatch = receipt?.json?.products.some((product) =>
        product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // Search in store name
      const storeMatch = receipt?.json?.shop_details?.shop_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());

      return productsMatch || storeMatch;
    });

    setFiltredData(results);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };

  // Search for the Receiptes
  useEffect(() => {
    // Set FiltredData once Receiptes is available
    if (Receiptes) {
      setFiltredData(Receiptes);
    }
  }, [Receiptes]);

  function SeeAllReciptce() {
    setFiltredData(Receiptes);
    setSearchTerm("");
  }

  // ANIMATION CONFIG
  const container = {
    hidden: { opacity: 1, scale: 1 },
    visible: {
      opacity: 1,
      scale: 1,

      transition: {
        duration: 0.5,
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const tour = JSON.parse(localStorage.getItem("tour"));

 
  return (
    <>


      <Navbar />
      <div className="  overflow-hidden  flex flex-col px-[1.5rem] pb-20  ">
        <Filter
          theme={theme}
          Receiptes={Receiptes}
          FiltredData={FiltredData}
          setFiltredData={setFiltredData}
          setFilterOpen={setFilterOpen}
          FilterOpen={FilterOpen}
        />
 

        <h1
          className={`  font-[700] w-full  gap-2 text-[1.3rem] flex ${
            isArabic ? " flex-row-reverse " : " flex-row"
          } flex`}
        >
          {t("receipts.title1")} <span className=" text-main">FTR Client</span>{" "}
        </h1>
        <p className=" text-sec text-[.8rem] pt-[.6rem] ">
          {t("receipts.par")}
        </p>
        <div
          className={`pt-[1.8rem] w-full  h-full flex ${
            isArabic ? " flex-row-reverse " : " flex-row"
          } gap-1 `}
        >
          <span className=" relative w-full ">
            <p
              className={`  text-[#9C9C9C]  flex flex-row gap-[.35rem] items-center  absolute  top-[1.5rem]  ${
                isArabic ? "   right-3" : " left-3 "
              }  `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M9.64153 8.21094H9.06072L8.85486 8.01243C9.31433 7.47865 9.65013 6.84991 9.83823 6.1712C10.0263 5.49249 10.0621 4.78059 9.94296 4.08645C9.59742 2.04259 7.89175 0.410438 5.83318 0.160469C5.10946 0.0689108 4.37438 0.144126 3.6842 0.38036C2.99402 0.616593 2.36703 1.00758 1.8512 1.52341C1.33537 2.03924 0.944382 2.66623 0.708149 3.35641C0.471916 4.04659 0.3967 4.78167 0.488258 5.50539C0.738227 7.56396 2.37038 9.26963 4.41424 9.61517C5.10838 9.73431 5.82028 9.69855 6.49899 9.51044C7.1777 9.32234 7.80644 8.98654 8.34022 8.52707L8.53873 8.73293V9.31374L11.6633 12.4384C11.9648 12.7398 12.4574 12.7398 12.7588 12.4384C13.0602 12.1369 13.0602 11.6443 12.7588 11.3429L9.64153 8.21094ZM5.23031 8.21094C3.39966 8.21094 1.9219 6.73318 1.9219 4.90252C1.9219 3.07187 3.39966 1.59411 5.23031 1.59411C7.06097 1.59411 8.53873 3.07187 8.53873 4.90252C8.53873 6.73318 7.06097 8.21094 5.23031 8.21094Z"
                  fill="#C1C1C1"
                />
              </svg>{" "}
            </p>
            <input
              value={searchTerm}
              onChange={handleInputChange}
              type="text"
              className={` text-[.75rem] px-8  ${
                isArabic ? "  text-right " : "  text-left"
              }    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
              placeholder={t("receipts.placeholder")}
            />
          </span>

          <button
            onClick={() => setFilterOpen(true)}
            className=" bg-main rounded-[.3rem]   py-[1rem] px-[.7rem]  justify-center items-center flex "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="11"
              viewBox="0 0 20 11"
              fill="none"
            >
              <path
                d="M1.41904 1.03192H19.2171M4.59726 5.48143H16.0389M8.41112 9.93094H12.225"
                stroke="white"
                stroke-width="1.27129"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div
          className={`pt-[1.1rem] flex ${
            isArabic ? " flex-row-reverse" : " flex-row"
          } justify-between w-full`}
        >
          <h1 className=" text-[1rem]  font-[600]  ">{t("receipts.title2")}</h1>
          {Receiptes && (
            <div
              onClick={SeeAllReciptce}
              className={`flex ${
                isArabic ? " flex-row-reverse " : " flex-row"
              } justify-center items-center gap-1 text-[0.75rem] font-medium`}
            >
              <p>{t("receipts.seeall")}</p>

              <span className={`${isArabic ? "  rotate-180" : " rotate-0"}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="8"
                  viewBox="0 0 5 8"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.81656 3.35536C3.94246 3.48143 4.01318 3.65231 4.01318 3.83048C4.01318 4.00865 3.94246 4.17954 3.81656 4.3056L1.2814 6.84165C1.15527 6.96772 0.984231 7.03852 0.805901 7.03848C0.627572 7.03844 0.456563 6.96755 0.330494 6.84143C0.204426 6.7153 0.133625 6.54426 0.133667 6.36593C0.133709 6.1876 0.204591 6.01659 0.330718 5.89052L2.39076 3.83048L0.330718 1.77044C0.208186 1.6437 0.140337 1.4739 0.141785 1.29761C0.143234 1.12133 0.213863 0.952661 0.338461 0.827945C0.463059 0.703229 0.631657 0.632441 0.807941 0.630827C0.984226 0.629212 1.15409 0.696901 1.28095 0.819314L3.81701 3.35492L3.81656 3.35536Z"
                    fill={theme ? "black" : "white"}
                  />
                </svg>
              </span>
            </div>
          )}
        </div>

        {isLoading ? (
          <ReceiptsSkeleton receipts={8} />
        ) : (
          <motion.div
            variants={container}
            initial="hidden"
            animate="visible"
            className=" w-full flex flex-col gap-[0.4rem] pt-[1rem]"
          >
            {!tour && <FakeReceipt />}

            {Receiptes.length <= 0 || !User ? (
              <NoReceipts />
            ) : (
              FiltredData?.map((item, index) => (
                <ProductDetails
                  FilterOpen={FilterOpen}
                  key={index}
                  data={item?.data}
                  source={item?.source}
                  filtredata={FiltredData}
                  Receiptes={Receiptes}
                  image={item?.image}
                  type={item?.file_type}
                  created_at={item?.created_at}
                  updated_at={item?.updated_at}
                  setNewItem={setNewItem}
                  newItem={newItem}
                  receipt={item.receipt}
                  isNew={item.isNew}
                  id={item.id}
                  json={item.json}
                  date={item.created_at}
                  setImgOpen={setImgOpen}
                />
              ))
            )}
          </motion.div>
        )}
      </div>
      {ImgOpen && <DisplayReceipt setImgOpen={setImgOpen} ImgOpen={ImgOpen} />}
   <Social EmailReceipts={EmailReceipts}  integration={intigration} setIsOpen={setloadingReceipt} isOpen={loadingReceipt} />
   {ConfirmIsOpen &&  <ConfirmReceipts data={EmailReceipts} setConfirmIsOpen={setConfirmIsOpen} />}
    </>
  );
}

export default Receipts;
