import React from 'react'
import { useTranslation } from 'react-i18next';
import receiptIndex from  "../assest/receiptIndex.svg"



function FakeReceipt() {
    const { t, i18n } = useTranslation();
      var isArabic = i18n.language.startsWith('ar')
  return (
    <div
    className={`  w-full px-[1.2rem] py-[.7rem]   h-full flex flex-col items-center  justify-between     border-[1px]   border-[#eeeeee] rounded-[.31rem] `}
  > 
     <div className=  {`w-full flex items-center gap-[.8rem]  ${isArabic  ? ' flex-row-reverse' :' flex-row' }`}>
    <div className={` w-full flex  ${isArabic  ? ' flex-row-reverse' :' flex-row' }  justify-between items-center`} >
      <div className=" flex w-full flex-col gap-1">
        <h1 className="  text-[.9rem] font-medium   ">Store</h1>
        <p className=" font-normal text-[.75rem] text-sec "> 16-12-2023 at 02:50:53  </p>
      </div>
     
      <button
          className={` bg-[#49d806] text-white w-[40%] px-1   text-[.8rem] py-[.3rem]    font-[600]  rounded-[.25rem]`}
        >
        paid
        </button>
    </div>
    <span className='relative    ReceiptBtn'>
       <span  className=" p-1 h-4   w-4 items-center flex  bg-opacity-30 rounded-[.12rem] bg-main">
      <span
        className={`  relative `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.42735 4.00033C4.30129 4.12624 4.1304 4.19696 3.95223 4.19696C3.77406 4.19696 3.60318 4.12624 3.47711 4.00033L0.941064 1.46518C0.814995 1.33905 0.744195 1.16801 0.744236 0.989678C0.744279 0.811349 0.81516 0.64034 0.941288 0.514271C1.06742 0.388203 1.23846 0.317402 1.41679 0.317444C1.59512 0.317486 1.76613 0.388367 1.8922 0.514495L3.95223 2.57453L6.01227 0.514495C6.13902 0.391963 6.30882 0.324114 6.4851 0.325562C6.66139 0.32701 6.83005 0.39764 6.95477 0.522238C7.07949 0.646836 7.15027 0.815434 7.15189 0.991718C7.1535 1.168 7.08581 1.33787 6.9634 1.46473L4.4278 4.00078L4.42735 4.00033Z"
            fill="#06C671"
          />
        </svg>
      </span>
      <span style={{
        height:'4rem',
        width:'4rem'
      }} id= {""}  className=" ReceiptBtn0 absolute">
      </span>
      <img style={{
          visibility:'hidden'
        }} id="Receipt"   className="  z-50 absolute     scale-[7]  top-0 -left-24 w-full " src={receiptIndex} alt="" />
    </span>
   
    </span>
    
   
</div>
    </div>
     
  )
}

export default FakeReceipt