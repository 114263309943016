import {React , useState} from "react";
import Average from "../../assest/Average.png";
import Highest from "../../assest/Highest.png";
import Lowset from "../../assest/Lowest.png";
import { useTranslation } from "react-i18next";
import ApexChart from "./Chartexp";

function Spending({  filteredData,Period ,setPeriod  }) {
  const [isChartChanged,setisChartChanged]=useState(true)

  const [state, setState] = useState(0);
  const [total, settotal] = useState(0);

  const [Totals,setTotals] = useState({
    average:'',
    highest:'',
    lowset:''
  })
  const {t, i18n} = useTranslation()
var isArabic = i18n.language.startsWith('ar');
  return (
    <div className=" w-full flex flex-col pb-7  ">
      <div onClick={ ()=> {setState(0); setisChartChanged(!isChartChanged)}}  
      className=  {`bg-main text-white items-start justify-center flex ${isArabic ? ' flex-row-reverse' : ' flex-row'  }  font-[700] text-[1.4rem] py-[1.1rem] pl-[1.8rem] pr-[.8rem] w-full h-[6.4rem] rounded-[.4rem] `}>
        <div className=" w-full  h-full flex flex-col gap-1">
          <h1 className=" text-[1.1rem] font-[500] ">{t('Stats.TSpending')}</h1>
          <span>{total}</span>
        </div>
        <span className=" ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M2.66282 12.0511L0.0209351 14.6401V7.20762H2.66282M7.06596 10.4307L5.68337 9.25068L4.42408 10.4131V3.68511H7.06596M11.4691 8.96888L8.82722 11.6108V0.162598H11.4691M13.9437 8.80156L12.3497 7.20762H16.7529V11.6108L15.1765 10.0344L8.82722 16.3309L5.77144 13.6714L2.44266 16.8945H0.0209351L5.7186 11.3113L8.82722 13.9356"
              fill="white"
            />
          </svg>
        </span>
      </div>
      <ApexChart settotal={settotal} isChartChanged={isChartChanged} Totals={Totals} setTotals={setTotals} filteredData={filteredData} Period={Period} setState={setState} state={state} />

      <div className=" w-full flex flex-row justify-between pt-[1rem] gap-2 text-center  ">
        <div onClick={ ()=> {setState(1); setisChartChanged(!isChartChanged)}   } className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] ">
          <img
            src={Average}
            className=" bg-[#FFF4C7] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium "> {t('Stats.AT')} <br /> {t('Stats.T')}</h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.average} </p>
        </div>
        <div onClick={ ()=> {setState(2) ; setisChartChanged(!isChartChanged) } }  className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] ">
          <img
            src={Highest}
            className=" bg-[#FFD9D9] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium ">{t('Stats.HT')}  <br /> {t('Stats.T')}</h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.highest} </p>
        </div>
        <div onClick={ ()=> {setState(3) ; setisChartChanged(!isChartChanged) } }  className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] ">
          <img
            src={Lowset}
            className=" bg-[#C7FFDA] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium ">{t('Stats.LT')}  <br /> {t('Stats.T')}</h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.lowset} </p>
        </div>
      </div>
    </div>
  );
}

export default Spending;
