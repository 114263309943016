import React from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function TopBar({text}) {

  const navto = useNavigate();

function goBack () { 
  navto(-1)
}

  return (
    <div className=' w-full flex flex-row  px-0  relative    justify-center  font-[700] text-[1.2rem] items-center '>
<span onClick={goBack} className=' absolute scale-105   left-0 top-0'>
 <ArrowBackIcon />
</span> 

{text}
    </div>
  )
}

export default TopBar