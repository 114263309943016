import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import { AnimatePresence, motion } from "framer-motion";
import { React, useEffect, useState, useContext } from "react";
import { Translation, useTranslation } from "react-i18next";
import PDFViewer from "./ReceiptsCompo/PDFveiw";
import ScannReImg from "../assest/ScannRe.svg";
import pdfImg from "../assest/pdf.svg";
import Finlogo from "../assest/FinLogo.png";
import gmailImg from "../assest/gmail.svg";
import outlookImg from "../assest/outLook.png";

import imgImport from "../assest/imgImport.svg";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { Usercontext } from "../context/Usercontexte";
import DOMPurify from "dompurify";


function ProductDetails({
  receipt,
  FilterOpen,
  filtredata,
  source,
  data,
  json,
  type,
  date,
  setNewItem,
  id,
  image,
  setOld,
  created_at,
  updated_at,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [PdfOpen, setPdfOpen] = useState(false);
  const [logo, setLogo] = useState({
    bg: "",
    img: null,
  });
  
  const { highlightedItems, setHighlightedItems } = useContext(Usercontext);
  
  const [Status, setStatus] = useState({
    text: "",
    style: "",
  });
  
  const sanitizedHTML = DOMPurify.sanitize(data?.body);
  
  
  useEffect(() => {
    // Retrieve the existing array of IDs from localStorage
    const highlighted = localStorage.getItem("highlightedItemIds");
    
    // If no existing data, set an empty array
    const parsedHighlighted = highlighted ? JSON.parse(highlighted) : [];
    
    // Set the parsed array as the initial state
    setHighlightedItems(parsedHighlighted);
  }, []);

  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  const removeItem = (id) => {
    // Remove all occurrences of the ID
    const updatedItemIds = highlightedItems.filter((itemId) => itemId !== id);

    // Save the updated array back to localStorage
    localStorage.setItem("highlightedItemIds", JSON.stringify(updatedItemIds));

    // Update the state to reflect the removal
    setHighlightedItems(updatedItemIds);
  };

  const From = data?.from.split("<");


  const items = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  useEffect(() => {
    if (json?.credit === 0) {
      setStatus({ text: t("receipts.paid"), style: " bg-[#49D806] " });
    } else if (json?.credit === json?.total) {
      setStatus({ text: t("receipts.Unpaid"), style: " bg-[#FF0101] " });
    } else if (json?.credit < json?.total && json?.credit != 0) {
      setStatus({
        text: t("receipts.Ppaid"),
        style: " bg-[#FFC51C] w-[70%] px-1 ",
      });
    } else {
      setStatus({
        text: t("receipts.refunded"),
        style: " bg-blue-400 w-[70%] px-1 ",
      });
    }
  }, [FilterOpen, isArabic, filtredata]);

  useEffect(() => {
    if (source === "Imported PDF Receipt") {
      setLogo({ img: pdfImg, bg: " bg-[#FFFCBF]" });
    } else if (source === "Scanned Receipt") {
      setLogo({ img: ScannReImg, bg: " bg-[#C9FFC8]" });
    } else if (source === "Imported Image Receipt") {
      setLogo({ img: imgImport, bg: " bg-[#DCFFFF]" });
    } else if (source === "gmail") {
        setLogo({ img: gmailImg, bg: " bg-[#FFE9E9]" });
    } else if (source === "outlook") {
      setLogo({ img: outlookImg, bg: " bg-[#E9EFFF]" });
    } else {
      setLogo({ img: Finlogo, bg: " bg-[#C9FFC8]" });
    }
  }, [FilterOpen, isArabic, filtredata]);

  const daysStayed = differenceInDays(
    new Date(updated_at),
    new Date(created_at)
  );

  return (
    <motion.div onClick={() => removeItem(id)} variants={items}>
      <div
        style={{
          boxShadow: highlightedItems?.includes(id)
            ? "  0px 8px 28px 0px rgba(89, 78, 241, 0.20)"
            : "",
          border: highlightedItems?.includes(id) ? "2px solid #06C671" : "",
        }}
        onClick={() => {
          setIsOpen(!isOpen);
          setNewItem(false);
        }}
        className={`  w-full px-[1.2rem] py-[.7rem]   h-full flex flex-col items-center  justify-between     border-[1px]   border-[#eeeeee] rounded-[.31rem] `}
      >
        <div
          className={`w-full flex items-center gap-[.8rem]  ${
            isArabic ? " flex-row-reverse" : " flex-row"
          }`}
        >
          <div
            className={` w-full flex  ${
              isArabic ? " flex-row-reverse" : " flex-row"
            }  justify-between items-center`}
          >
            
              <div
                className={`w-[4rem] h-[2.4rem]  rounded-md flex items-center justify-center   ${logo.bg}  mr-2`}
              >
                <img className=" w-[45%] " src={logo.img} alt="rece" />
              </div>
             

            <div className=" flex w-full flex-col gap-1">
              <h1 className="  text-[.9rem] font-medium   ">
              {json ? json?.shop_details?.shop_name :  (source == 'gmail' || source == 'outlook'  ? From[0] : source ) }
              </h1>
              <p className=" font-normal text-[.75rem] text-sec ">
                {" "}
                {format(new Date(date), "dd-MM-yyyy 'at' HH:mm:ss")}{" "}
              </p>
            </div>

            <button
              className={`   ${
                json ? Status.style : "  bg-[#49D806]"
              }    text-white w-[40%] px-1   text-[.8rem] py-[.3rem]    font-[600]  rounded-[.25rem]`}
            >
              {json ? Status.text : "Paid"}
            </button>
          </div>
          <span className=" p-1 h-4   items-center flex  bg-opacity-30 rounded-[.12rem] bg-main">
            <span
              className={` ${
                isOpen ? "rotate-180  " : "rotate-0"
              } duration-150   `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.42735 4.00033C4.30129 4.12624 4.1304 4.19696 3.95223 4.19696C3.77406 4.19696 3.60318 4.12624 3.47711 4.00033L0.941064 1.46518C0.814995 1.33905 0.744195 1.16801 0.744236 0.989678C0.744279 0.811349 0.81516 0.64034 0.941288 0.514271C1.06742 0.388203 1.23846 0.317402 1.41679 0.317444C1.59512 0.317486 1.76613 0.388367 1.8922 0.514495L3.95223 2.57453L6.01227 0.514495C6.13902 0.391963 6.30882 0.324114 6.4851 0.325562C6.66139 0.32701 6.83005 0.39764 6.95477 0.522238C7.07949 0.646836 7.15027 0.815434 7.15189 0.991718C7.1535 1.168 7.08581 1.33787 6.9634 1.46473L4.4278 4.00078L4.42735 4.00033Z"
                  fill="#06C671"
                />
              </svg>
            </span>
          </span>
        </div>

        <AnimatePresence>
          {isOpen &&
            (type == "image" ? (
              <img
                className=" w-full h-full"
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : type == "pdf_file" ? (
              <iframe
                src={URL.createObjectURL(image)}
                width="100%"
                height="500px"
              />
            ) : type == "email" ? 
            <div className=" h-[500px]     overflow-auto py-36 ">
            <div
              className=" pb-40 scale-[.6] -mt-[17rem]  z-30 pt h-full w-full bg-white     "
              dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            />
          </div>
            : (
              <motion.div
                initial={{ height: "0rem", opacity: 0 }}
                animate={{ height: "100%", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.1 }}
                className={`  ${json ? " " : "hidden"}  w-full    `}
              >
                <div
                  className={`  font-normal text-[.8rem] text-sec pt-[.7rem]   ${
                    isArabic
                      ? " flex-row-reverse"
                      : " flex-row items-start pr-7 "
                  }  w-full justify-between `}
                >
                  <p> {json?.shop_details?.contact} </p>
                  <span
                    className={` flex gap-1 ${
                      isArabic ? " flex-row-reverse" : " flex-row"
                    }`}
                  >
                    <p className=" "> {json?.products?.length} </p>{" "}
                    <span> {t("receipts.items")}</span>
                  </span>
                </div>

                <div
                  onClick={() => setPdfOpen(true)}
                  className="pt-[1.6rem] pl-1 pr-[.8rem] flex-col  w-full justify-center     flex items-center "
                >
                  <div
                    className={`  font-[600]  text-[.96rem]  w-full  flex ${
                      isArabic ? " flex-row-reverse" : " flex-row "
                    }  justify-between    w-full `}
                  >
                    <span className=" flex  flex-col gap-3  w-full font-normal">
                      <h1 className=" text-[1rem] opacity-80 font-[550] ">
                        {" "}
                        {t("receipts.Product")}
                      </h1>
                      {json?.products?.map((i) => (
                        <p className=" px-1">
                          {" "}
                          {i.name?.length >= 8
                            ? i.name?.substring(0, 8)?.toLowerCase() + "..."
                            : i.name?.toLowerCase()}{" "}
                        </p>
                      ))}
                    </span>

                    <span className=" text-center flex flex-col gap-3  w-full font-normal">
                      <h1 className=" text-[1rem] opacity-80 font-[550] ">
                        {" "}
                        {t("receipts.Quantity")}{" "}
                      </h1>
                      {json?.products?.map((i) => (
                        <p>{i?.quantity} </p>
                      ))}
                    </span>

                    <span
                      className={` ${
                        isArabic ? "  text-left pl-1 " : " text-right"
                      }    pr-1 flex flex-col gap-3  w-full font-normal`}
                    >
                      <h1 className=" text-[1rem] opacity-80 font-[550] ">
                        {t("receipts.Price")}
                      </h1>
                      {json?.products?.map((i) => (
                        <p> {i?.price} </p>
                      ))}
                    </span>
                  </div>

                  <div className="  w-full items-center flex flex-col ">
                    <span className=" w-full my-2 h-[1px] bg-gray-200 "></span>
                    <div className=" flex flex-col  w-full   text-base  gap-[.2rem] ">
                      <span
                        className={`flex w-full ${
                          isArabic ? " flex-row-reverse  text-sm" : "  flex-row"
                        }  justify-between`}
                      >
                        <h1 className=" font-[600] ">{t("receipts.Total")}</h1>
                        <h1 className=" font-[600] text-[.9rem] ">
                          {" "}
                          {json?.total}{" "}
                        </h1>
                      </span>

                      <span
                        className={`flex w-full ${
                          isArabic ? " flex-row-reverse text-sm " : "  flex-row"
                        }  justify-between`}
                      >
                        <h1 className=" font-[600] ">{t("receipts.Credit")}</h1>
                        <h1 className=" font-[600] text-[.9rem] ">
                          {" "}
                          {json?.credit}{" "}
                        </h1>
                      </span>
                    </div>

                    <span className=" my-[.9rem] w-[100%] h-[.4px] bg-opacity-30 bg-main  "></span>
                    <p className=" text-[.68rem] leading-4 ">
                      {" "}
                      <span className=" font-[600] ">
                        {" "}
                        {t("receipts.Important")}{" "}
                      </span>{" "}
                      {t("receipts.IportantT", { num: 2 })}{" "}
                    </p>
                  </div>
                  <div className="  text-center w-full text-[.75rem] pb-1 pt-[.9rem] font-[500] ">
                    {t("receipts.thanks")}
                  </div>
                </div>
              </motion.div>
            ))}
        </AnimatePresence>
      </div>

      {PdfOpen && <PDFViewer onclick={setPdfOpen} receipt={receipt} />}
    </motion.div>
  );
}

export default ProductDetails;
